<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>3/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><a href="/post-property-listing">Step 1</a></li>
                            <li><a href="/post-property-listing-2">Step 2</a></li>
                            <li><a class="active" href="javascript:;">Step 3</a></li>
                            <li><a href="javascript:;">Step 4</a></li>
                            <li><a href="javascript:;">Step 5</a></li>
                            
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">  
                        <div class="property-box">
                                
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Hybrid-Listing</h3>
                                </div>				
                                <p>You may assign this listing to multiple Estate Agents with our digital-brokerage widget, enhancing the reach and accelerating speed-to-market via the entire or co-agency network with leveraged resources, amplifying your chances of success while maintaining control of the original listing.</p>				
                                <div class="form-group">
                                    <label><a href="javascript:;">"Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices."</a></label>
                                    <!-- <label for="">"Choice Of Either 1 Selection, Not Allowed To Have Multiple Choices."</label> -->
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="Assign-To-Recommended-Estate-Agent">
                                        <label class="form-check-label w-100" for="Assign-To-Recommended-Estate-Agent">
                                            Assign To Recommended Estate Agent
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="Find-My-Own">
                                        <label class="form-check-label w-100" for="Find-My-Own">
                                            Find My Own/Co-Broking Estate Agent
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="Attend-To-Inquiry">
                                        <label class="form-check-label w-100" for="Attend-To-Inquiry">
                                            Attend To Inquiry By Myself
                                        </label>
                                    </div>
                                    <div class="d-flex">
                                        <div class="notify-box">
                                            <span>Prefers To Display Contact Information</span> <a href="#" class="yes"><img src="images/yes.svg" alt=""> YES</a> <a href="#" class="no"><img src="images/no.svg" alt=""> No</a>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Enriched Contents - FengShui Mapping</h3>
                                </div>				
                                <div class="row row-cols-md-2 row-cols-1">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Property Identification</label>											
                                            <input type="text" class="form-control" placeholder="Year Completed/1st Occupied">
                                        </div>	
                                    </div>									
                                    <div class="col">
                                        <!-- <div class="form-group">
                                            <label for=""></label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value="" id="Landed">
                                                <label class="form-check-label w-100" for="Landed">
                                                    Landed
                                                </label>
                                            </div>	
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value="" id="High-Rise">
                                                <label class="form-check-label w-100" for="High-Rise">
                                                    High-Rise
                                                </label>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>	
                                <br>
                                <div class="comman-title">
                                    <h4>Property Facing</h4>
                                </div>		
                                <div class="row row-cols-md-3 row-cols-1">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Block/Building Facing</label>
                                            <select class="form-control">
                                                <option>Main Block/Building Facing</option>
                                            </select>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Door Facing</label>
                                            <select class="form-control">
                                                <option>Main Door Facing</option>
                                            </select>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Balcony/Window Facing</label>
                                            <select class="form-control">
                                                <option>Main Balcony/Window Facing</option>
                                            </select>
                                        </div>	
                                    </div>			
                                </div>	
                                <br>										
                                <div class="comman-title">
                                    <h4>Space Sector</h4>
                                </div>		
                                <div class="row row-cols-md-2 row-cols-1">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Door/Entrance Sector</label>
                                            <select class="form-control">
                                                <option>Main Door/Entrance Sector</option>
                                            </select>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main Living-Hall Sector</label>
                                            <select class="form-control">
                                                <option>Main Living-Hall Sector</option>
                                            </select>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Kitchen Sector</label>
                                            <select class="form-control">
                                                <option>Kitchen Sector</option>
                                            </select>
                                        </div>	
                                    </div>			
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Main/Master Room Sector</label>
                                            <select class="form-control">
                                                <option>Main/Master Room Sector</option>
                                            </select>
                                        </div>	
                                    </div>			
                                </div>											
                            </div> 

                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>AI-Generative <img src="images/check-icon.svg" alt="" class="mt-1"></h3>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Language</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">
                                            <!-- <input type="checkbox" class="btn-check" id="btn-check-1" checked autocomplete="off">
                                            <label class="btn" for="btn-check-1">English</label> -->
                                            <div v-for="item in checkboxLanguagelist" :key="item.id">
                                                <input class="btn-check" type="checkbox" 
                                                :id="item.id"
                                                :value="item.id"
                                                v-model="language_id">
                                                <label class="btn" :for="item.id">
                                                    {{ item.name }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Audience</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">

                                            <div v-for="item in checkboxTAlist" :key="item.id">
                                                <input class="btn-check" type="checkbox" 
                                                :id="item.id"
                                                :value="item.id"
                                                v-model="checkboxFF">
                                                <label class="btn" :for="item.id">
                                                    {{ item.name }}
                                                </label>
                                            </div>

                                            <!-- <input type="checkbox" class="btn-check" id="btn-check-14" checked autocomplete="off">
                                            <label class="btn" for="btn-check-14">Overseas Property Investors (Migrants/Expatriates)</label> -->
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Look & Feel</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">
                                            <!-- <input type="checkbox" class="btn-check" id="btn-check-20" checked autocomplete="off">
                                            <label class="btn" for="btn-check-20">Luxurious</label> -->

                                            <div v-for="item in checkboxLFlist" :key="item.id">
                                                <input class="btn-check" type="checkbox" 
                                                :id="item.id"
                                                :value="item.id"
                                                v-model="look_feel_id">
                                                <label class="btn" :for="item.id">
                                                    {{ item.name }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="comman-title mb-0">
                                            <h4>Platform</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom-check-btn">
                                            <input type="checkbox" class="btn-check" id="btn-check-26" checked autocomplete="off">
                                            <label class="btn" for="btn-check-26">YouTube</label>
                                            
                                            <input type="checkbox" class="btn-check" id="btn-check-27" autocomplete="off">
                                            <label class="btn" for="btn-check-27">FaceBook</label>

                                            <input type="checkbox" class="btn-check" id="btn-check-28" autocomplete="off">
                                            <label class="btn" for="btn-check-28">TikTok</label>

                                            <input type="checkbox" class="btn-check" id="btn-check-29" autocomplete="off">
                                            <label class="btn" for="btn-check-29">Instagram</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-md-end">
                                <!-- <button class="btn comman-btn-6">Previous</button> -->
                                <router-link :to="`/post-property-listing-2/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                                <button class="btn comman-btn-2 ms-3" type="submit">Next</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

</template>
  
  <script>
  export default {
    name: 'PostPropertyListingStep3', 
    data() {
        return {
            checkboxTAlist: [],
            checkboxLanguagelist: [],
            checkboxLFlist: [],
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        }
    },
    created() {
        this.fetchOptions();
    },
    methods: {
        async submitPost() {            
            this.$router.push('/post-property-listing-4/'+this.itemId);  
        },        
        async fetchOptions() {
            
            // Get facilities list
            // try {
            //     const response = await this.$axios.post('api/pr-master-facilities-list',
            //     {                    
            //         parent_id : "0",
            //         keyword : "", 
            //         show_in_project: 1,
            //         show_in_property:0,
            //         last_record_number : 0                     
            //     });
            //     this.optionsFacilities = response.data.output.map(item => ({
            //     value: item.id,
            //     label: item.name
            //     }));
            // } catch (error) {
            //     console.error('Error fetching options:', error);
            // }

            // Target Audience
            try {
                const response = await this.$axios.post('api/target-audience-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.checkboxTAlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                console.log(this.checkboxFFlist);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Language list
            try {
                const response = await this.$axios.post('api/language-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.checkboxLanguagelist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.language_ISO_639_2_code == '' ? item.name : item.language_ISO_639_2_code
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Look and feed list
            try {
                const response = await this.$axios.post('api/pr-look-and-feel-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.checkboxLFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        }
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  