<template>
  <div>
    <h2>About Us</h2>
    <p>This is the About Us page.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped>
/* Add styles specific to the About Us page here */
</style>
