<template>
  <section class="profile-se">
      <div class="container">
          <div class="top-tabs-design">
            <ul>
              <li><a href="#" class="active">My profile</a></li>
              <li><a href="#">Leads & Status Management</a></li>						
              <li><a href="#">Account</a></li>
              <li><a href="#">Wishlist - Saved & Favorites</a></li>
              <li><a href="#">Reports & Performance Scores</a></li>
              <li><a href="#">AI Creatives</a></li>
            </ul>
          </div>									
          <div class="row">
            <form @submit.prevent="updateProfileBasic">
            <div class="col-md-9">
              <div class="profile-card-edit">
                <div class="comman-title">
                  <h3>Profile Picture</h3>											
                </div>
                <div class="profile-card-edit-box">
                  <figure v-if="imageUrl">
                    <!-- <img :src="imageUrl" > -->
                    <img :src="computedImageSrc" @error="handleImageError" >
                  </figure>
                  
                    <div class="profile-user-content w-100">
                      
                      <!-- <form @submit.prevent="uploadImage">
                        <input type="file" @change="onFileChange" />
                        <button type="submit">Upload</button>
                      </form> -->
                      <!-- <div v-if="imageUrl">
                        <h3>Preview:</h3>
                          <img :src="imageUrl" alt="Profile Image" width="200" />
                        </div> -->
                        <!-- <form @submit.prevent="uploadImage">
                          <input type="file" @change="onFileChange" />
                          <button type="submit">Upload</button>
                        </form> -->
                      <div class="form-group" @input="handleInputChangeUsername" 
                      v-if="((this.userProfile.is_corporate || this.userProfile.is_business) && this.userProfile.is_active )">
                        <label>User Name</label>
                        <input type="text" placeholder="Username" class="form-control" v-model="this.userProfile.user_name_url" >
                      </div>
                      <div class="d-flex">
                        
                        <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
                        <a href="javascript:;"  @click.prevent="triggerFileInput" class="comman-btn-2"><b>Upload Profile Picture</b></a>
                        <a href="javascript:;" @click.prevent="removeProfilePhoto"  class="comman-btn-4 ms-3"><b>Remove Picture</b></a>
                      </div>
                    </div>
                  
                </div>
              </div>
              <div class="profile-card-edit">
                <div class="comman-title">
                  <h3>Your Profile</h3>											
                </div>
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>First Name </label>
                    <input type="text" placeholder="First Name" class="form-control" v-model="this.userProfile.first_name" >
                  </div>
                  <div class="form-group col-md-3">
                    <label>Last Name</label>
                    <input type="text" placeholder="Last Name" class="form-control" v-model="this.userProfile.last_name"  >
                  </div>
                  <div class="form-group col-md-3">
                    <label>Birth Date</label>
                    <input type="date" placeholder="Last Name" class="form-control" v-model="this.userProfile.date_of_birth">												
                  </div>
                  <div class="form-group col-md-3">
                    <label>Gender</label>
                    <select class="form-control" v-model="this.userProfile.gender">
                      <option value="">Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="profile-card-edit">
                <div class="comman-title">
                  <h3>Your Contact</h3>											
                </div>
                <div class="row">
                  <div class="form-group col-md-4">
                    <label>Phone Number</label>
                    <input type="text" placeholder="Phone Number" class="form-control" v-model="this.userProfile.contact_phone">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Verify Phone Number</label>
                    <input type="text" placeholder="Verify Phone Number" class="form-control">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Email</label>
                    <input type="email" placeholder="Email" class="form-control" v-model="this.userProfile.contact_email">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Facebook</label>
                    <input type="text" placeholder="Facebook। Linkedin" class="form-control" v-model="this.userProfile.facebook_url">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Linkedin</label>
                    <input type="text" placeholder="Facebook। Linkedin" class="form-control" v-model="this.userProfile.linkedin_url">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Twitter</label>
                    <input type="text" placeholder="Twitter। Instagram" class="form-control" v-model="this.userProfile.twitter_url">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Instagram</label>
                    <input type="text" placeholder="Twitter। Instagram" class="form-control" v-model="this.userProfile.instagram_url">
                  </div>
                  <div class="form-group col-md-4">
                    <label>Tiktok</label>
                    <input type="text" placeholder="Tiktok। YouTube" class="form-control" v-model="this.userProfile.tiktok_url">
                  </div>
                  <div class="form-group col-md-4">
                    <label>YouTube</label>
                    <input type="text" placeholder="Tiktok। YouTube" class="form-control" v-model="this.userProfile.youtube_url">
                  </div>
                </div>
              </div>
              <div class="profile-card-edit">
                <div class="comman-title">
                  <h3>Agency/Company </h3>											
                </div>
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>Agency Name</label>
                    <input type="text" placeholder="Agency Name" class="form-control">
                  </div>
                  <div class="form-group col-md-3">
                    <label>Agency License</label>
                    <input type="text" placeholder="Agency License" class="form-control">
                  </div>
                  <div class="form-group col-md-3">
                    <label>Agency Registration No</label>
                    <input type="text" placeholder="Agency Registration No" class="form-control">
                  </div>
                  <div class="form-group col-md-3">
                    <label>Position Held</label>
                    <input type="text" placeholder="Position Held" class="form-control">
                  </div>
                  <div class="form-group mb-0 col-md-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="otr-foen-ta-1">
                      <label class="form-check-label w-100" for="otr-foen-ta-1">
                        Estate Agent<a href="#"> Sign-Up / Upgrade</a>
                      </label>
                    </div>	
                  </div>
                </div>
              </div>
              <div class="profile-card-edit">
                <div class="comman-title">
                  <h3>Your Services</h3>											
                </div>
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>My Services</label>
                    <select class="form-control">
                      <option value="">My Services</option>													
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Company Name</label>
                    <input type="text" placeholder="Company Name" class="form-control">
                  </div>
                  <div class="form-group col-md-3">
                    <label>Availability Days</label>
                    <input type="text" placeholder="Availability Days" class="form-control">												
                  </div>
                  <div class="form-group col-md-3">
                    <label>Working Hours</label>
                    <input type="text" placeholder="Working Hours" class="form-control">												
                  </div>
                </div>
              </div>

              <div class="profile-card-edit">
                <div class="comman-title">
                  <h3>Your ....</h3>											
                </div>
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>My.....</label>
                    <select class="form-control">
                      <option value="">My .....</option>													
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Company ....</label>
                    <input type="text" placeholder="Company ...." class="form-control">
                  </div>
                  <div class="form-group col-md-3">
                    <label>Availability ....</label>
                    <input type="date" placeholder="Last Name" class="form-control">												
                  </div>
                  <div class="form-group col-md-3">
                    <label>Working ....</label>
                    <input type="date" placeholder="Last Name" class="form-control">												
                  </div>
                </div>
              </div>
              <div class="profile-card-edit">
                <div class="comman-title">
                  <h3>About You</h3>											
                </div>										
                <div class="form-group mb-0">
                  <label>About You</label>
                  <textarea class="form-control" placeholder="About You" v-model="this.userProfile.about_you"></textarea>
                </div>
              </div>	
              <!-- <button class="btn comman-btn-2 ms-3">Save</button>	 -->
              <div class="profile-card-edit d-flex">
                <button class="btn comman-btn-2" type="submit">Save</button>
                <button class="btn comman-btn-4 ms-2">Cancel</button>
              </div>
            </div>
            </form>
            <div class="col-md-3">
              <div class="profile-package-card">
                <h3>Package</h3> 
                <h4>Private</h4>
                <p>Valid Until 31 Dec 2024</p>
                <a href="#" class="comman-btn-2"><img src="frontend/images/Choose-plan.svg" alt=""> Choose plan</a>
                <br>
                <h4>Credits</h4>
                <p><b>00 Credits</b></p>
                <a href="#" class="comman-btn-4"><img src="frontend/images/Choose-plan.svg" alt=""> Top-Up</a>
              </div>
              <div class="profile-package-card">										
                <h4 class="mb-0">Availability</h4>
                <p>Monday - Friday 9am - 1pm</p>										
                <h4 class="mb-0">Reach Me</h4>
                <p class="mb-0">Jennywilson@gmail.com</p>
                <p>+60001113333</p>
                <h4 >My Socials</h4>			
                <div class="social">
                  <a href="">
                    <img src="frontend/images/tweet-ico.svg">
                  </a>
                  <a href="">
                    <img src="frontend/images/fb-ico.svg">
                  </a>
                  <a href="">
                    <img src="frontend/images/linkedin-ico.svg">
                  </a>
                  <a href="">
                    <img src="frontend/images/youtube-ico.svg">
                  </a>
                </div>							
              </div>
            </div>
          </div>						
      </div>
  </section>
  <!-- <div v-if="isAuthenticated">
    <h1> Welcome, {{ user.name }}! </h1>
    <h1> Welcome, {{ user.profile_photo_name }}! </h1>
  </div>
  <div v-else>
    <p>Please log in to view your profile.</p>
  </div> -->
</template>

<script>
import { CONSTANT } from '@/constants/constants';
import { mapState } from 'vuex';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
export default {
  data() {
    return {
      //userProfile: null, // Initial state
      selectedFile: null,
      imageUrl: 'frontend/images/profile.svg',
    };
  },
  computed: {
    ...mapState(['isAuthenticated', 'user','userProfile']),
    computedImageSrc() {
      return this.userProfile.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.userProfile.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
      }
  },
  async created() {
    const token = localStorage.getItem('userToken');
    
    if (this.isAuthenticated) {
      
      try {
        const response = await this.$axios.post('/api/profile', {
          api_token: token
        }); 

        //console.log('API Response:', response.data); // Check API response
        await this.$store.dispatch('profileUpdate', response.data);
        //this.userProfile = JSON.stringify(response.data); // Update data property
        // console.log('Updated userProfile:', this.userProfile); // Verify update

      } catch (error) {
        console.error('Failed to fetch profile:', error);
      }

    } else {
      console.log('User is not authenticated');
    }
  },
  methods: {
    async onFileChange(event) {
      // console.log(this.user.id);
      this.selectedFile = event.target.files[0];
      console.log("File selected:", this.selectedFile);
      if (this.selectedFile) {
        // Generate a preview URL
        this.imageUrl = URL.createObjectURL(this.selectedFile);
        this.computedImageSrc = URL.createObjectURL(this.selectedFile);
        
        const token = localStorage.getItem('userToken');
        // Create a FormData object
        const formData = new FormData();
        formData.append('profile_photo_name', this.selectedFile);
        formData.append('dataJson', JSON.stringify({
          user_id: this.user.id,
          api_token: token,
        }));
        try {
          const response = await this.$axios.post('api/update-profile-photo', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          toastr.success(response.data.message, 'Success', { timeOut: 3000 });


            const response_profile = await this.$axios.post('/api/profile', {
              api_token: token
            }); 

            console.log('API Response:', response_profile.data); // Check API response
            await this.$store.dispatch('profileUpdate', response_profile.data);
            
          //await this.$store.dispatch('profileUpdate', response.data);
        } catch (error) {                
            if (error.response && error.response.data) {
                this.errors = this.extractErrors(error.response.data.errors || {});
            } else {
                console.error('An unexpected error occurred:', error);
            }
        } finally {
          this.loading = false;
        }
      }
    },
    async uploadImage_000() {
      if (!this.selectedFile) {
        alert("No file selected.");
        return;
      }
      const token = localStorage.getItem('userToken');
      // Create a FormData object
      const formData = new FormData();
      formData.append('profile_photo_name', this.selectedFile);
      formData.append('dataJson', JSON.stringify({
        user_id: this.user.id,
        api_token: token,
      }));
      try {
        const response = await this.$axios.post('api/update-profile-photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
         toastr.success(response.data.message, 'Success', { timeOut: 3000 });
         await this.$store.dispatch('profileUpdate', response.data);
      } catch (error) {                
          if (error.response && error.response.data) {
              this.errors = this.extractErrors(error.response.data.errors || {});
          } else {
              console.error('An unexpected error occurred:', error);
          }
      } finally {
        this.loading = false;
      }
    },
    triggerFileInput() {
      // Trigger the file input click event
      this.$refs.fileInput.click();
    },
    async removeProfilePhoto() {
      
      const token = localStorage.getItem('userToken');
      const response_remove_photo = await this.$axios.post('/api/remove-profile-photo', {
        user_id: this.user.id,
        api_token: token
      }); 
      console.log('API Remove photo Response:', response_remove_photo.data); 
      //await this.$store.dispatch('profileUpdate', response_remove_photo.data);
    },
    async updateProfileBasic() {        
            if (this.loading) return;
            this.loading = true;
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/update-basic-profile', {
                    user_id:this.user.id,
                    api_token:token,
                    first_name: this.userProfile.first_name,
                    last_name: this.userProfile.last_name,
                    gender: this.userProfile.gender,
                    date_of_birth: this.userProfile.date_of_birth,
                    contact_phone: this.userProfile.contact_phone,
                    contact_email: this.userProfile.contact_email,
                    facebook_url: this.userProfile.facebook_url,
                    linkedin_url: this.userProfile.linkedin_url,
                    twitter_url: this.userProfile.twitter_url,
                    instagram_url: this.userProfile.instagram_url,
                    tiktok_url: this.userProfile.tiktok_url,
                    youtube_url: this.userProfile.youtube_url,
                    about_you: this.userProfile.about_you
                });
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
    
            } catch (error) {
                
                if (error.response && error.response.data) {
                    this.errors = this.extractErrors(error.response.data.errors || {});
                } else {
                    console.error('An unexpected error occurred:', error);
                }
            } finally {
                this.loading = false;
            }
    },
    async handleInputChangeUsername() {
      
      try {
        const token = localStorage.getItem('userToken');
        await this.$axios.post('api/update-user-name-url', {
                    user_id:this.user.id,
                    api_token:token,                    
                    user_name_url: this.userProfile.user_name_url
        });
        
      } catch (error) {
                
          if (error.response && error.response.data) {
              this.errors = this.extractErrors(error.response.data.errors || {});
          } else {
              console.error('An unexpected error occurred:', error);
          }
      } finally {
          this.loading = false;
      }
    }
  }
};
</script>
