// src/constants/constantsProperty.js

export const OPTIONS_GROUP_1 = [
    { value:'1', label:	'Resale / Subsale (Pre-Owned)'},
    { value:'2', label:	'Rent (Entire Unit)'},
    { value:'3', label:	'Rent (Rooms / Co-Living / Co-Working)'},
    { value:'4', label:	'Developer (New Launch / Off-The-Plan)'},
    { value:'5', label:	'Developer (TOP / Completed)'},
    { value:'6', label:	'Classified (Hybrid-Listing - FSBO / FRBO)'}
];

export const PROPERTY_TYPE = [
    { id: 1, name: 'Residential', parent_id: 0 },
    { id: 2, name: 'Commercial & Industrial', parent_id: 0 },
    { id: 3, name: 'All HDB', parent_id: 1 },
    { id: 4, name: 'All Non-Landed (Private)', parent_id: 1 },
    { id: 5, name: 'All Landed', parent_id: 1 },
    { id: 6, name: 'HDB Flat', parent_id: 3 },
    { id: 7, name: 'HDB Executive', parent_id: 3 },
    { id: 8, name: 'Apartment', parent_id: 4 },
    { id: 9, name: 'Walk-Up Apartment', parent_id: 4 },
    { id: 10, name: 'Condominium', parent_id: 4 },
    { id: 11, name: 'Executive Condominium', parent_id: 4 },
    { id: 12, name: 'Terrace / Link House', parent_id: 5 },
    { id: 13, name: 'Town House', parent_id: 5 },
    { id: 14, name: 'Cluster House', parent_id: 5 },
    { id: 15, name: 'Conservative House', parent_id: 5 },
    { id: 16, name: 'Semi-Detached/Zero-Lot', parent_id: 5 },
    { id: 17, name: 'Detached/Bungalow', parent_id: 5 },
    { id: 18, name: 'Good Class Bungalow (GCB)', parent_id: 5 },
    { id: 19, name: 'Vacant Land', parent_id: 5 },
    { id: 20, name: 'All Commercial', parent_id: 2 },
    { id: 21, name: 'All Industrial', parent_id: 2 },
    { id: 22, name: 'Retail', parent_id: 20 },
    { id: 23, name: 'Office', parent_id: 20 },
    { id: 24, name: 'Hotel / Hostel', parent_id: 20 },
    { id: 25, name: 'Shopping Mall', parent_id: 20 },
    { id: 26, name: 'Medical Suite', parent_id: 20 },
    { id: 27, name: 'ShopHouse', parent_id: 20 },
    { id: 28, name: 'HDB ShopHouse', parent_id: 20 },
    { id: 29, name: 'Vacant Land', parent_id: 20 },
    { id: 30, name: 'Office', parent_id: 21 },
    { id: 31, name: 'Warehouse (B1)', parent_id: 20 },
    { id: 32, name: 'Factory (B2)', parent_id: 21 },
    { id: 33, name: 'Vacant Land', parent_id: 20 }
  ];