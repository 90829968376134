<template>    
   <section class="listing-se">
        <div class="container">
            
            <div class="filter-bar-se mb-0">
                <div class="comman-title mb-0">
                    <h3 class="mb-2">My Property</h3>
                    <!-- <span>Showing 10 properties</span> -->
                </div>
                <div class="filter-right-bar">
                    <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/filter-ico.svg"> Filter By
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li><a class="dropdown-item" href="#">111</a></li>
                            <li><a class="dropdown-item" href="#">111</a></li>								
                        </ul>
                    </div>
                    <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/Sort-By.svg"> Sort By
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li><a class="dropdown-item" href="#">111</a></li>
                            <li><a class="dropdown-item" href="#">111</a></li>								
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row row-cols-lg-3 row-cols-sm-2">
                <div class="col" v-for="item in items" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="heart-pro">
                                <router-link :to="`/post-property-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                            </div>
                            <picture>
                                <img src="frontend/images/about-3.png">
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>s$</small> 3000-40,000</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>Direct<br>Owner</small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>SingPass<br>Verified </small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>Documents<br>Verified</small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>Hybrid-Listing<br>Owners </small>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>                
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  export default {
    name: 'MyProperty', 
    data() {
        return {      
            items: [],       
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
    },
    created() {
       this.fetchOptions();
    },
    methods: {
        async fetchOptions() {
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-list',
                {                    
                    user_id : this.user.id,
                    api_token : token, 
                });
                this.items  = response.data.output;
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
        },
      
    }   
    
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  