<template>
    <section class="signin-sec">
        <div class="container">
            <div class="signin-bx m-auto">
                <h1>Verify number</h1>
                <p class="text-center">We sand verification code to <b>+65 0000 0000</b>. Please input the code below.</p>
                <form>
                    <div class="form-group">
                        <input type="text" name="" id="" placeholder="Verification code" class="form-control">
                    </div>
                    <div class="form-group">
                        <p><b>Resend code: <a href="javascript:;"><span>03.50</span></a></b></p>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <input type="text" name="" id="" placeholder="Captcha code" class="form-control">
                        </div>
                        <div class="col-6 form-group">
                            <img src="frontend/images/capcha.png" alt="">
                        </div>
                    </div> 
                    <p>Type the characters you see in the picture above</p>
                    <div class="form-group">
                        <button type="submit" class="btn comman-btn-2 w-100">Verify	</button>
                    </div>
                    
                    <small class="mb-0 text-center d-block">
                        Have account?  <a href=""><b>Sign in</b></a>
                    </small>
                </form>
            </div>
        </div>
    </section>
</template>
  
  <script>
  export default {
   
  };
  </script>
  