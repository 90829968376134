<template>
  <div class="footer-sec">
    <div class="footer-menu-outer">
      <div class="container">
        <div class="row">
          <div class="col-md-3">					
            <div class="footer-privacy-logo">
              <figure>
                <img src="frontend/images/logo.png">
              </figure>
              <p>Empowers Property Owners To Partner With Estate Agents, Connecting To Buyers & Renters For Seamless Onboarding Experience.</p>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">				
              <div class="col-md-3">
                <div class="footer-menu footer-nav">
                  <h3>Discover</h3>
                  <ul>
                    <li><a href="">Hybrid Listing</a></li>
                    <li><a href="">Feng Shui Mapping</a></li>
                    <li><a href="">AI-Assist & Creatives</a></li>
                    <li><a href="">X-Border Properties</a></li>
                    <li><a href="">explore more features</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="footer-menu footer-nav">
                  <h3>Quick links</h3>
                  <ul>
                    <li><a href="">Most Viewed</a></li>
                    <li><a href="">Popular Searches</a></li>
                    <li><a href="">Other Page</a></li>
                    <li><a href="">Other Page</a></li>
                    <li><a href="">Other Page</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="footer-menu footer-nav">
                  <h3>Company</h3>
                  <ul>
                    <li><a href="">About</a></li>
                    <li><a href="">Contact</a></li>
                    <li><a href="">FAQs</a></li>
                    <li><a href="">Privacy policy</a></li>
                    <li><a href="">Terms and conditions</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3">
                <div class="news-quick">
                  <h3>Get news & Update</h3>
                  <form>
                    <div class="form-group">
                      <input type="text" name="email" class="form-control" placeholder="Email address">
                    </div>
                    <div class="">
                      <button type="submit" class="btn comman-btn-3 w-100">Submit</button>
                    </div>
                  </form>
                  <p><i><img src="frontend/images/email-footer-ico.png"></i>example@company.com</p>
                  <p><i><img src="frontend/images/phone-footer-ico.png"></i>000-000-0000</p>
                  <p><i><img src="frontend/images/map-footer-ico.png"></i>Singapore</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-outer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-8">
            <p>2024 All Rights Reserved | <b>PROPFACE</b> Pte Ltd</p>
          </div>
          <div class="col-md-4">
            <div class="social">
              <a href="">
                <img src="frontend/images/tweet-ico.svg">
              </a>
              <a href="">
                <img src="frontend/images/fb-ico.svg">
              </a>
              <a href="">
                <img src="frontend/images/linkedin-ico.svg">
              </a>
              <a href="">
                <img src="frontend/images/youtube-ico.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>
  
  <script>
  export default {
    name: 'FooterPart'
  }
  </script>
  
  <style scoped>
  footer {
    background: #333;
    color: white;
    padding: 1rem;
    text-align: center;
  }
  </style>
  