<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>4/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <!-- <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><a href="/post-property-listing">Step 1</a></li>
                            <li><a href="/post-property-listing-2">Step 2</a></li>
                            <li><a href="/post-property-listing-3">Step 3</a></li>
                            <li><a  class="active" href="javascript:;">Step 4</a></li>
                            <li><a href="javascript:;">Step 5</a></li>								
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box mb-4">							 
                            <div class="property-inner-box mb-0">
                                <div class="comman-title">
                                    <h3>Upload Files & Insert Media Links</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-4">
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1"  data-bs-toggle="modal" data-bs-target="#upload-photos-modal">Actual Unit Photos <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">Unit Layout & Site Plan <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">Marketing Brochure
                                                <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">
                                                Verify Authentic Badge
                                                    <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <a href="#" class="comman-btn-6"> + Add Media Link</a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div class="property-box">							 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Upload Documents For Verification & Earn Verified/Trusted Badge</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">Property Title & Deeds <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1"> Sale & Purchase Agreement
                                                    <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">Loan Facility Agreement <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">Utility, Tax & Assessment Bills <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">Registered Intent To Sell (For HDB Only) <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="#" class="comman-btn-1">Letter Of Appointment (Estate Agents) <img src="images/upload-icon.svg" alt=""></a>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div class="d-flex justify-content-md-end">
                                <!-- <a class="btn comman-btn-6" href="/post-property-listing-3">Previous </a> -->
                                <router-link :to="`/post-property-listing-3/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                                <button class="btn comman-btn-2 ms-3">Next</button>
                            </div>
                        </div>
                    </form>

                    <br>
                    <div class="d-flex">
                        <a href="#" class="comman-btn-1"  data-bs-toggle="modal" data-bs-target="#credit-top-up-modal">Credit top up</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-calculate-modal"> OTR Calculate</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-calculate-modal-result"> OTR Calculate Result </a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otp-1"> OTP 1</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otp-2"> OTP 2</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-1"> OTR 1</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-2"> OTR 2</a>
                    </div>
                </div>
                
            </div>
            
        </div>

        <!-- Modal Popup -->

        <!-- upload photos modal -->
        <div class="modal fade" id="upload-photos-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">			
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                <div class="modal-body text-center">
                    <form id="file-upload-form" class="uploader">
                        <input id="file-upload" type="file" @change="onFileChange" name="fileUpload" multiple accept="image/*" />
                    
                            <label for="file-upload" id="file-drag">
                                <img id="file-image" src="#" alt="Preview" class="hidden">
                                <div id="start">
                                    <img src="images/big-upload-icom.svg" alt="">
                                    <div>
                                        <h5>Please Upload Your Site Plan</h5>
                                        <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PDF, SVG <br>
                                        &lt;Max Size Per File&gt; 05 - 10 PX <br>
                                        &lt;Max No. Of Files&gt; 4 </p>
                                    </div>	
                                    <div id="notimage" class="hidden">Please select an image</div>
                                    <!-- <span id="file-upload-btn" class="btn btn-primary">Select a file</span> -->
                                </div>
                                <div id="response" class="hidden">
                                    <div id="messages"></div>
                                    <progress class="progress" id="file-progress" value="0">
                                        <span>0</span>%
                                    </progress>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- credit top up -->
        <div class="modal fade" id="credit-top-up-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body text-center">
                        <img src="images/credit-top-up.png" width="200px" class="mb-4" alt="">
                        <h4>Please top up! </h4>
                        <h5>You don't have sufficient credit to use this feature. </h5>
                        <p>The AI-generated content will require a minimum of 10 credits if the listing is approved with a score above 80%.</p>
                        <div class="">
                            <a href="#" class="comman-btn-2 ms-auto me-auto mb-3 w-50">Top-Up</a>
                            <a href="#" class="comman-btn-6 w-50 ms-auto me-auto">Go Back</a>
                        </div>				
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTR Calculate -->
        <div class="modal fade" id="otr-calculate-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-For-Rent-tab" data-bs-toggle="pill" data-bs-target="#pills-For-Rent" type="button" role="tab" aria-controls="pills-For-Rent" aria-selected="true">For-Rent</button>
                            </li>
                            <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-Calculate-tab" data-bs-toggle="pill" data-bs-target="#pills-Calculate" type="button" role="tab" aria-controls="pills-Calculate" aria-selected="false">Calculate</button>
                            </li>						
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade" id="pills-For-Rent" role="tabpanel" aria-labelledby="pills-For-Rent-tab">...</div>
                            <div class="tab-pane fade show active" id="pills-Calculate" role="tabpanel" aria-labelledby="pills-Calculate-tab">
                                <div class="otr-calculate-title">
                                    <h2>Rental Term Sheet ( Proposal )</h2>
                                    <p>*Subject To Agreement & Payment Consideration</p>
                                </div>
                                <div class="comman-title">
                                    <h3>Offer To Rent</h3>
                                </div>
                                <div class="row row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Asking Monthly Rent ( S$ )</label>
                                            <input class="form-control" type="text" placeholder="Asking Monthly Rent ( S$ )">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Preferred Term (Mths)</label>
                                            <select class="form-control">
                                                <option>12</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Security Deposit ( Mths )</label>
                                            <select class="form-control">
                                                <option>1</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Utility Deposit ( Mths )</label>
                                            <select class="form-control">
                                                <option>0.5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Lump-Sum Pre-Pay Rent (Mths) <i class="fa fa-question-circle"></i> </label>
                                            <select class="form-control">
                                                <option>3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Proposed Pre-Pay Rebate ( % ) <i class="fa fa-question-circle"></i> </label>
                                            <select class="form-control">
                                                <option>2.5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">									
                                        <div class="form-group">										
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="Diversity-Friendly">
                                                <label class="form-check-label w-100" for="Diversity-Friendly">
                                                    Diversity Friendly <i class="fa fa-question-circle"></i>
                                                </label>
                                            </div>	
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="Pets-Allowed">
                                                <label class="form-check-label w-100" for="Pets-Allowed">
                                                    Pets Allowed <i class="fa fa-question-circle"></i>
                                                </label>
                                            </div>	
                                        </div>
                                    </div>
                                    <div class="col">									
                                        <div class="form-group">										
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="Enable-Rent-Financing">
                                                <label class="form-check-label w-100" for="Enable-Rent-Financing">
                                                    Enable Rent Financing <i class="fa fa-question-circle"></i>
                                                </label>
                                            </div>	
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="I-Agree-To">
                                                <label class="form-check-label w-100" for="I-Agree-To">
                                                    I Agree To The <a href="#"> Terms Of Use </a>
                                                </label>
                                            </div>	
                                        </div>
                                    </div>
                                </div>
                            </div>						
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- OTR Calculate -->
        <div class="modal fade" id="otr-calculate-modal-result" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Estimated Rent Cashflow</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
                        <div class="table-responsive custom-table-design">
                            <table class="table-borderless">
                                <thead class="bg-light">
                                    <tr>
                                        <th>Total Payable By Renter/Tenant</th>
                                        <th>8,100.00</th>
                                        <th>8,300.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td>1.50%</td>
                                        <td>123.00</td>
                                    </tr>
                                </tbody>
                                <thead class="bg-light">
                                    <tr>
                                        <th>Total Receivable by landlord</th>
                                        <th>7,200.00</th>
                                        <th>7,200.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td class="text-danger">1.50%</td>
                                        <td class="text-danger">123.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTP 1 -->
        <div class="modal fade otp" id="otp-1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Generate Sale Proposal</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
                        <div class="comman-title">
                            <h3>Offer To Purchase (OTP)</h3>
                        </div>
                        <form>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Offer Price ($)</label>
                                        <input type="text" class="form-control" placeholder="Offer Price ($)">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Loan Margin (%)</label>
                                        <select class="form-control">
                                            <option>Loan Margin</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Discount (%)</label>
                                        <select class="form-control">
                                            <option>Discount</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Additional Rebate (%)</label>
                                        <select class="form-control">
                                            <option>Additional Rebate (%)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Earnest Deposit (%)</label>
                                        <select class="form-control">
                                            <option>Earnest Deposit (%)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Estate Agent Fee (%) By Purchaser</label>
                                        <select class="form-control">
                                            <option>Estate Agent Fee (%) By Purchaser</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end">
                                <button class="comman-btn-2 d-inline-block text-end">Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTP 2 -->
        <div class="modal fade otp" id="otp-2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Generate Offer-To-Purchase (OTP) Proposal</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>

                        <div class="table-responsive">
                            <table class="table-borderless">							
                                <tbody>
                                    <tr>
                                        <td>S&PA Price Before Price Discount</td>
                                        <td>100.00%</td>
                                        <td>200,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>S&PA Price After Price Discount</td>
                                        <td>95.00%</td>
                                        <td>190,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Additional Rebate</td>
                                        <td>1.00%</td>
                                        <td>1,900.00</td>
                                    </tr>
                                    <tr>
                                        <td>Loan Amount</td>
                                        <td>80.00%</td>
                                        <td>152,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Earnest Deposit  On S&PA Price</td>
                                        <td>2.00%</td>
                                        <td>3,800.00</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional Platform Free On Payment</td>
                                        <td>1.50%</td>
                                        <td>5.70</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div class="gray-box">
                            <div>Total Payable By Purchaser (Initial)</div>
                            <div>3,805.70</div>
                        </div>
                        <div class="gray-box">
                            <div>Total Receivable by seller/vendor (initial)</div>
                            <div>3,800.00</div>
                        </div>

                        <form>
                            <div class="comman-title mt-5">
                                <h3>Property Details</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Name</label>
                                        <input type="text" class="form-control" placeholder="Property Name">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Address</label>
                                        <input type="text" class="form-control" placeholder="Property Address">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Type</label>
                                        <select class="form-control">
                                            <option>Property Type</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Floor Area/Built-Up (SQF)</label>
                                        <select class="form-control">
                                            <option>Floor Area/Built-Up (SQF)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Furnishing Level</label>
                                        <select class="form-control">
                                            <option>Furnishing Level</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>S&PA Date</label>
                                        <select class="form-control">
                                            <option>S&PA Date</option>
                                        </select>
                                    </div>
                                </div> 
                            </div>

                            <div class="comman-title mt-3">
                                <h3>Proposer Information (Buyer/Estate Agent)</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>EMail Address</label>
                                        <input type="text" class="form-control" placeholder="EMail Address">
                                    </div>
                                </div>							
                                <div class="col">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" class="form-control" placeholder="Phone Number">
                                    </div>
                                </div>
                                <div class="col">
                                    <a href="#"><img src="images/orange-file.svg" alt=""> OTP Form / S&PA</a> <i class="fa fa-question-circle"></i>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="terms">
                                        <label class="form-check-label w-100" for="terms">
                                            <b>I Agree To The <a href="#"> Terms Of Use</a> </b>
                                        </label>
                                    </div>	
                                </div>
                                <div class="col">
                                    <div class="text-end">
                                        <button class="comman-btn-2 d-inline-block text-end">Generate Proposal</button>
                                    </div>
                                </div>	
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTR 1 -->
        <div class="modal fade otp" id="otr-1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Rental Term Sheet (Proposal)</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
    

                        <form>
                            <div class="comman-title">
                                <h3>Offer To Rent (OTR)</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Monthly Rent Offer (s)</label>
                                        <input type="text" class="form-control" placeholder="Monthly Rent Offer (s)">
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Preferred Term (Mths)</label>
                                        <select class="form-control">
                                            <option>12</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Security Deposit (Mths)</label>
                                        <select class="form-control">
                                            <option>1</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Utility Deposit (Mths)</label>
                                        <select class="form-control">
                                            <option>0.5</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Lump-Sum Pre-Pay Rent (Mths) <i class="fa fa-question-circle"></i></label>
                                        <select class="form-control">
                                            <option>3</option>
                                        </select>
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Proposed Pre-Pay Rebate (%) <i class="fa fa-question-circle"></i></label>
                                        <select class="form-control">
                                            <option>2.5</option>
                                        </select>
                                    </div>
                                </div>  
                                <div class="col">								
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="Rent-Financing-Option">
                                        <label class="form-check-label w-100" for="Rent-Financing-Option">
                                            <b>Rent Financing Option <i class="fa fa-question-circle"></i></b>
                                        </label>
                                    </div>	
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="terms-1">
                                        <label class="form-check-label w-100" for="terms-1">
                                            <b>I Agree To The <a href="#"> Terms Of Use</a> <i class="fa fa-question-circle"></i></b>
                                        </label>
                                    </div>	
                                </div>
                                <div class="col">
                                    <div class="text-end">
                                        <button class="comman-btn-2 d-inline-block text-end">Next</button>
                                    </div>
                                </div>	
                            </div>
    
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTR 2-->
        <div class="modal fade otp" id="otr-2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Generate Offer-To-Rent (OTR) Proposal</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
                        
                        <div class="table-responsive custom-table-design">
                            <table class="table-borderless">
                                <thead class="bg-light">
                                    <tr>
                                        <th>Total Payable By Renter/Tenant</th>
                                        <th>8,200.00</th>
                                        <th>8,323.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td>1.50%</td>
                                        <td>123.00</td>
                                    </tr>
                                </tbody>
                                <thead class="bg-light">
                                    <tr>
                                        <th>TOTAL Receivable By Landlord</th>
                                        <th>7,200.00</th>
                                        <th>7,200.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td>1.50%</td>
                                        <td>123.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <form>
                            <div class="comman-title">
                                <h3>Property Details</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Name</label>
                                        <input type="text" class="form-control" placeholder="Property Name">
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Address</label>
                                        <input type="text" class="form-control" placeholder="Property Address">
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Type</label>
                                        <select class="form-control">
                                            <option>Property Type</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Floor Area/Built-Up (SQF)</label>
                                        <select class="form-control">
                                            <option>Floor Area/Built-Up (SQF)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Furnishing Level</label>
                                        <select class="form-control">
                                            <option>Furnishing Level</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Rent Free Fr-To</label>
                                        <select class="form-control">
                                            <option>Rent Free Fr-To</option>
                                        </select>
                                    </div>
                                </div> 
                            </div>
                            
                            <div class="comman-title">
                                <h3>Proposer Information (Renter-Tenant/Estate Agent)</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>EMail Address</label>
                                        <input type="text" class="form-control" placeholder="EMail Address">
                                    </div>
                                </div>							
                                <div class="col">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" class="form-control" placeholder="Phone Number">
                                    </div>
                                </div>
                                <div class="col">								
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="otr-foen-ta-1">
                                        <label class="form-check-label w-100" for="otr-foen-ta-1">
                                            <!-- <b>OTP Form / TA <i class="fa fa-question-circle"></i></b> -->
                                            <a href="#"><img src="images/orange-file.svg" alt=""> OTP Form / TA</a> <i class="fa fa-question-circle"></i>
                                        </label>
                                    </div>	
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="terms-2">
                                        <label class="form-check-label w-100" for="terms-2">
                                            <b>I Agree To The <a href="#"> Terms Of Use</a> <i class="fa fa-question-circle"></i></b>
                                        </label>
                                    </div>	
                                </div>
                                <div class="col">
                                    <div class="text-end">
                                        <button class="comman-btn-2 d-inline-block text-end">Generate Proposal</button>
                                    </div>
                                </div>	
                            </div>
    
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>
  
  <script>
  import { OPTIONS_GROUP_1 } from '@/constants/constantsProperty';
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  export default {
    name: 'PostPropertyListingStep4', 
    data() {
        return {
            selectedGroup1: '',
            optionsGroup1: OPTIONS_GROUP_1,
            unitiPhotos: []

        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        }
    },
    methods: {
        async submitPost() {            
            this.$router.push('/post-property-listing-5/'+this.itemId);  
        },  
        async onFileChange(event) {
            
            const files = event.target.files;
            this.selectedFiles = Array.from(files);

            console.log("File selected:", this.selectedFiles);
            if (this.selectedFiles) {
                
                const token = localStorage.getItem('userToken');
                const formData = new FormData();

                // Append files to FormData
                this.selectedFiles
                .forEach(file => {
                    formData.append('actual_unit_photos[]', file);
                });
                formData.append('dataJson', JSON.stringify({
                user_id: this.user.id,
                api_token: token,
                property_listing_id: this.itemId,
                }));


                try {
                const response = await this.$axios.post('api/add-actual-unit-photos', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                });
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                this.loading = false;
                }
            }
        },
        async onFileChangeSingle(event) {
            
            this.selectedFile = event.target.files[0];
            console.log("File selected:", this.selectedFile);
            if (this.selectedFile) {
                // Generate a preview URL
                this.imageUrl = URL.createObjectURL(this.selectedFile);
                this.computedImageSrc = URL.createObjectURL(this.selectedFile);
                
                const token = localStorage.getItem('userToken');
                // Create a FormData object
                const formData = new FormData();
                formData.append('actual_unit_photos[]', this.selectedFile);
                formData.append('dataJson', JSON.stringify({
                user_id: this.user.id,
                api_token: token,
                property_listing_id: this.itemId,
                }));


                try {
                const response = await this.$axios.post('api/add-actual-unit-photos', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                });
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });


                    const response_profile = await this.$axios.post('/api/profile', {
                    api_token: token
                    }); 

                    console.log('API Response:', response_profile.data); // Check API response
                    await this.$store.dispatch('profileUpdate', response_profile.data);
                    
                //await this.$store.dispatch('profileUpdate', response.data);
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                this.loading = false;
                }
            }
        },
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  