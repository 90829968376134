<template>
  
  <section class="hero-banner-sec">     
    <picture>
      <img src="frontend/images/banner.jpg"> 
    </picture>
    <div class="container">
      <div class="banner-outer">
        <div class="row">
          <div class="col-md-8">              
            <div class="caption-heading">           
              <h1><span>Beyond Property Listings...</span> <br>PROPverse Your Property Universe!</h1>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                <button class="nav-link active" id="Buy-tab" data-bs-toggle="tab" data-bs-target="#Buy" type="button" role="tab" aria-controls="Buy" aria-selected="true">Buy</button>
                </li>
                <li class="nav-item" role="presentation">
                <button class="nav-link" id="Rent-tab" data-bs-toggle="tab" data-bs-target="#Rent" type="button" role="tab" aria-controls="Rent" aria-selected="false">Rent</button>
                </li> 
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="Buy" role="tabpanel" aria-labelledby="Buy-tab">
                  <div class="search-form">
                    <div class="tab-title form-group">                      
                      <div class="row align-items-center">
                        <div class="col-md-6 col-lg-6">
                          <p>Find Properties By Your Destiny Profile <i class="fa fa-question-circle"></i></p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-6">                         
                          <select class="form-control" title="Gender">
                            <option>Gender</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                          </select>                         
                        </div>
                        <div class="col-md-3 col-lg-3 col-6">                         
                          <select class="form-control" title="Date Of Birth">
                            <option>Date Of Birth</option>
                            <option>Date Of Birth</option>
                            <option>Date Of Birth</option>
                          </select>                         
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row align-items-center">
                        <div class="col-md-9 col-lg-9">
                          <input type="text" class="form-control" name="emailid" placeholder="Search By Property Name, District, HDB Estate, Area, MRT Or School" >
                        </div>                        
                        <div class="col-md-3 col-lg-3">
                          <button type="submit" class="w-100 btn comman-btn-2">
                            <i class="me-2"><img src="frontend/images/search-ico.svg"></i>Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-4 col-6">                        
                        <select class="form-control" title="Listing Type">
                          <option>Listing Type</option>
                          <option>Listing Type</option>
                          <option>Listing Type</option>
                        </select>
                      </div>
                      <div class="col-md-4 col-6">                        
                        <select class="form-control" title="Property Type">
                          <option>Property Type</option>
                          <option>Property Type</option>
                          <option>Property Type</option>
                        </select>                       
                      </div>
                      <div class="col-md-4">
                        <button type="submit" class="w-100 form-control">
                          More Filters <i class="ms-2"><img src="frontend/images/filter-ico.svg"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Rent" role="tabpanel" aria-labelledby="Rent-tab">
                  <div class="search-form">
                    <div class="tab-title form-group">                      
                      <div class="row align-items-center">
                        <div class="col-md-6 col-lg-6">
                          <p>Find Properties By Your Destiny Profile <i class="fa fa-question-circle"></i></p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-6">                         
                          <select class="form-control" title="Gender">
                            <option>Gender</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                          </select>                         
                        </div>
                        <div class="col-md-3 col-lg-3 col-6">                         
                          <select class="form-control" title="Date Of Birth">
                            <option>Date Of Birth</option>
                            <option>Date Of Birth</option>
                            <option>Date Of Birth</option>
                          </select>                         
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row align-items-center">
                        <div class="col-md-9 col-lg-9">
                          <input type="text" class="form-control" name="emailid" placeholder="Search by Property Name, District, HDB Estate, Area, MRT Or School" >
                        </div>                        
                        <div class="col-md-3 col-lg-3">
                          <button type="submit" class="w-100 btn comman-btn-2">
                            <i class="me-2"><img src="frontend/images/search-ico.svg"></i>Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-md-4 col-6">                        
                        <select class="form-control" title="Listing Type">
                          <option>Listing Type</option>
                          <option>Listing Type</option>
                          <option>Listing Type</option>
                        </select>
                      </div>
                      <div class="col-md-4 col-6">                        
                        <select class="form-control" title="Property Type">
                          <option>Property Type</option>
                          <option>Property Type</option>
                          <option>Property Type</option>
                        </select>                       
                      </div>
                      <div class="col-md-4">
                        <button type="submit" class="w-100 form-control">
                          More Filters <i class="ms-2"><img src="frontend/images/filter-ico.svg"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>              
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="browse-property-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-9">
          <div class="property-slider-outer">
            <div class="comman-title">
              <h3>Browse Property Type</h3>
            </div>              
            <div class="pro-type-carousel owl-carousel owl-theme">
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico1.png">
                    </picture>
                    <h3>HDB</h3>
                    <small>45 properties</small>
                  </div>
                </div>
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico2.png">
                    </picture>
                    <h3>Non-Landed Residential</h3>
                    <small>45 properties</small>
                  </div>
                </div>
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico3.png">
                    </picture>
                    <h3>Landed Residential</h3>
                    <small>45 properties</small>
                  </div>
                </div>
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico4.png">
                    </picture>
                    <h3>Commercial & Industrial</h3>
                    <small>45 properties</small>
                  </div>
                </div>
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico1.png">
                    </picture>
                    <h3>HDB</h3>
                    <small>45 properties</small>
                  </div>
                </div>
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico2.png">
                    </picture>
                    <h3>Non-Landed Residential</h3>
                    <small>45 properties</small>
                  </div>
                </div>
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico3.png">
                    </picture>
                    <h3>Landed Residential</h3>
                    <small>45 properties</small>
                  </div>
                </div>
                <div class="item">
                  <div class="bpt-box">
                    <picture>
                      <img src="frontend/images/property-ico4.png">
                    </picture>
                    <h3>Commercial & Industrial</h3>
                    <small>45 properties</small>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-3">
          <div class="own-property-bx">
            <h3>Create & Own Your Property Listing Now!</h3>
            <a href="" class="comman-btn-2">Start Free <i class="ms-2 align-middle d-inline-block"><img src="frontend/images/start-ico.svg"></i></a>
          </div>
        </div>
      </div>
      <div class="desc-info-box">
        <p><b>71% Of Owners</b> Wished They've More Control Over Listings Of Their Property Marketed By Estate Agents (Source: NAR 2023, Seller & Buyer Profile).
        <b>PROP</b>FACE enables you to craft unique details highlighting your property's charm, with flexibility to decide on the level of Estate Agent's involvement that suits you, because you know your property best after all.</p>
      </div>
    </div>
  </section>


  <section class="h-it-work-sec">
    <div class="container">
      <div class="comman-title text-center">
        <h3>How It Works, <span>Differently!?</span></h3>
      </div>
      <div class="desc-info-box text-center">
        <p>
          <b>82%</b> of <b>Buyers & Renters</b>  felt frustrated by property listings description that lack details from Sellers.
          <br><br>
          <b>84%</b> of <b>Estate Agents</b> acknowledged that generic listings don't showcase the property's true potential by <b>Owners</b>.
          <br><small><i>(Source: 2023 Consumer Property Search Habits Report)</i></small>
        </p>
      </div>
      <div class="row">
        <div class="col-md-3 col-6">
          <div class="h-it-work-box text-center">
            <div class="h-it-work-box-title">
              <span>1st In the Market!</span>
              <h5>Hybrid-Listings</h5>
            </div>
            <div class="h-it-work-box-content">
              <img src="frontend/images/1.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="h-it-work-box text-center">
            <div class="h-it-work-box-title">
              <span>Community Scoring</span>
              <h5>Crowd Source</h5>
            </div>
            <div class="h-it-work-box-content">
              <img src="frontend/images/3.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="h-it-work-box text-center">
            <div class="h-it-work-box-title">
              <span>Accuracy Comparison</span>
              <h5>A.I & Database</h5>
            </div>
            <div class="h-it-work-box-content">
              <img src="frontend/images/2.png" alt="">
            </div>
          </div>
        </div>
        
        <div class="col-md-3 col-6">
          <div class="h-it-work-box text-center">
            <div class="h-it-work-box-title">
              <span><b>BONUS FEATURE</b></span>
              <h5>Feng Shui Mapping</h5>
            </div>
            <div class="h-it-work-box-content">
              <img src="frontend/images/4.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="property-features-sec">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 col-lg-7">
          <div class="feature-tabslist">
            <ul class="nav nav-tabs" id="myTab-2" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="Hybrid-tab" data-bs-toggle="tab" data-bs-target="#Hybrid" type="button" role="tab" aria-controls="Hybrid" aria-selected="true">
                  <small>1st In the Market!</small>
                  <span>Hybrid-Listings</span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="Crowd-tab" data-bs-toggle="tab" data-bs-target="#Crowd" type="button" role="tab" aria-controls="Crowd" aria-selected="false">
                  <small>Community Scoring</small>
                  <span>Crowd Source</span>
                </button>
              </li> 
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="ai-tab" data-bs-toggle="tab" data-bs-target="#ai" type="button" role="tab" aria-controls="ai" aria-selected="false">
                  <small>Accuracy Comparison</small>
                  <span>A.I & Database</span>
                </button>
              </li> 
              <!-- <li class="nav-item w-100-lg" role="presentation">
                <button class="nav-link" id="Feng-tab" data-bs-toggle="tab" data-bs-target="#Feng" type="button" role="tab" aria-controls="Feng" aria-selected="false">
                  <small>BONUS FEATURE</small>
                  <span>Feng Shui Mapping</span>
                </button>
              </li>  -->
            </ul>
            <div class="tab-content" id="myTab-2Content">
              <div class="tab-pane " id="Hybrid" role="tabpanel" aria-labelledby="Hybrid-tab">
                <!-- <div class="comman-title">
                  <h4>Hybrid-Listings</h4>
                </div> -->
                <div class="feature-list-bx">                   
                  <ol>
                    <li>Earn Trust Badge To Combat Fake Or Shadow Listings</li>
                    <li>Participate As Local/House Resident In PropertyRatings &/Or Reviews</li>
                    <li>Build Profile & Goodwill, Personalize It As Your Own Brand</li>
                    <li>Property Verification By Estate Agents; Genuine Leads & Anti-Hijack Listings</li>
                  </ol>
                  <div class="readmore">
                    <a href="">Read more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" id="Crowd" role="tabpanel" aria-labelledby="Crowd-tab">
                <!-- <div class="comman-title">
                  <h4>Crowd Source</h4>
                </div> -->
                <div class="bonus-feature-box">
                  <span>BONUS FEATURE</span><br>
                  Feng Shui Mapping
                </div>
                <div class="feature-list-bx">                   
                  <ol>
                    <li>Earn Trust Badge To Combat Fake Or Shadow Listings</li>
                    <li>Participate As Local/House Resident In PropertyRatings &/Or Reviews</li>
                    <li>Build Profile & Goodwill, Personalize It As Your Own Brand</li>
                    <li>Property Verification By Estate Agents; Genuine Leads & Anti-Hijack Listings</li>
                  </ol>
                  <div class="readmore">
                    <a href="">Read more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="ai" role="tabpanel" aria-labelledby="ai-tab">
                <!-- <div class="comman-title">
                  <h4>A.I & Database</h4>
                </div> -->
                <div class="bonus-feature-box">
                  <span>BONUS FEATURE</span><br>
                  Feng Shui Mapping
                </div>
                <div class="feature-list-bx">                   
                  <ol>
                    <li>Earn Trust Badge To Combat Fake Or Shadow Listings</li>
                    <li>Participate As Local/House Resident In PropertyRatings &/Or Reviews</li>
                    <li>Build Profile & Goodwill, Personalize It As Your Own Brand</li>
                    <li>Property Verification By Estate Agents; Genuine Leads & Anti-Hijack Listings</li>
                  </ol>
                  <div class="readmore">
                    <a href="">Read more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Feng" role="tabpanel" aria-labelledby="Feng-tab">
                <div class="comman-title">
                  <h4>Feng Shui Mapping</h4>
                </div>
                <div class="feature-list-bx">                   
                  <ol>
                    <li>Earn Trust Badge To Combat Fake Or Shadow Listings</li>
                    <li>Participate As Local/House Resident In PropertyRatings &/Or Reviews</li>
                    <li>Build Profile & Goodwill, Personalize It As Your Own Brand</li>
                    <li>Property Verification By Estate Agents; Genuine Leads & Anti-Hijack Listings</li>
                  </ol>
                  <div class="readmore">
                    <a href="">Read more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-5 d-md-flex align-items-center">
          <div class="fea-pic">
            <figure>
              <img src="frontend/images/feature-img.jpg">
            </figure>
            <div class="mark-logo">
              <img src="frontend/images/logo.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="offer-pro-sec">
    <div class="container">
      <div class="comman-title d-md-flex align-items-center justify-content-between">
        <div class="offer-heading">
          <span>Browse more offers</span>
          <h3>Developer Projects</h3>
        </div>
        <div class="readmore ms-md-auto">
          <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
        </div>
      </div>
      <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
              <div class="pro-specify">
                <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
              </div>
              <div class="pro-tag">
                <a href="#">De</a>
                <a href="#">Condo</a>
                <a href="#">Freehold</a>
              </div>
              <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="recnet-list-sec">
    <div class="container">
      <div class="comman-title d-md-flex align-items-center justify-content-between">
        <div class="offer-heading">
          <span>Browse more offers</span>
          <h3>Most Recent Property Listings</h3>
        </div>
        <div class="readmore ms-md-auto">
          <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
        </div>
      </div>
      <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img4.jpg">
              <img src="frontend/images/project-img4.jpg">
              <img src="frontend/images/project-img4.jpg">
              <img src="frontend/images/project-img4.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
              <div class="pro-specify">
                <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
              </div>
              <div class="pro-tag">
                <a href="#">De</a>
                <a href="#">Condo</a>
                <a href="#">Freehold</a>
              </div>
              <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img5.jpg">
              <img src="frontend/images/project-img5.jpg">
              <img src="frontend/images/project-img5.jpg">
              <img src="frontend/images/project-img5.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="offer-pro-sec">
    <div class="container">
      <div class="comman-title d-md-flex align-items-center justify-content-between">
        <div class="offer-heading">
          <span>Browse more offers</span>
          <h3>Featured Properties (For Sale / For Rent)</h3>
        </div>
        <div class="readmore ms-md-auto">
          <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
        </div>
      </div>
      <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="recnet-list-sec">
    <div class="container">
      <div class="comman-title d-md-flex align-items-center justify-content-between">
        <div class="offer-heading">
          <span>Browse more offers</span>
          <h3>Enriched Property Listings (FengShui Map) <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Property Listings With Feng Shui Map"></i></h3>
        </div>
        <div class="readmore ms-md-auto">
          <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
        </div>
      </div>
      <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="offer-pro-sec">
    <div class="container">
      <div class="comman-title d-md-flex align-items-center justify-content-between">
        <div class="offer-heading">
          <span>Browse more offers</span>
          <h3>Classified Property Listings <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Classified Property Listings"></i></h3>
        </div>
        <div class="readmore ms-md-auto">
          <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
        </div>
      </div>
      <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img4.jpg">
              <img src="frontend/images/project-img4.jpg">
              <img src="frontend/images/project-img4.jpg">
              <img src="frontend/images/project-img4.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img5.jpg">
              <img src="frontend/images/project-img5.jpg">
              <img src="frontend/images/project-img5.jpg">
              <img src="frontend/images/project-img5.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
              <img src="frontend/images/project-img6.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="recnet-list-sec">
    <div class="container">
      <div class="comman-title d-md-flex align-items-center justify-content-between">
        <div class="offer-heading">
          <span>Browse more offers</span>
          <h3>Curated Property Collections (Malaysia & Others)  <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Curated Property Collections (Malaysia & Others) "></i></h3>
        </div>
        <div class="readmore ms-md-auto">
          <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
        </div>
      </div>
      <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
              <img src="frontend/images/project-img1.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
              <img src="frontend/images/project-img2.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
              <img src="frontend/images/project-img3.jpg">
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a href="">Property name</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> 3000-40,000</span>
                </div>
              </div>
              <div class="property-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <span>4.5</span>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                <div class="pro-specify">
                  <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                  <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                  <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                </div>
                <div class="pro-tag">
                  <a href="#">De</a>
                  <a href="#">Condo</a>
                  <a href="#">Freehold</a>
                </div>
                <ul>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico1.png">
                  </figure>
                  <small>Direct<br>Owner</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico2.png">
                  </figure>
                  <small>SingPass<br>Verified </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico3.png">
                  </figure>
                  <small>Documents<br>Verified</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico4.png">
                  </figure>
                  <small>Fengshui<br>Map</small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/specify-ico5.png">
                  </figure>
                  <small>Hybrid-Listing<br>Owners </small>
                </li>
                <li>
                  <figure>
                    <img src="frontend/images/Down-arrow.svg">
                  </figure>
                  <small>BMV</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="explore-loc-sec">
    <div class="container">
      <div class="row d-md-flex align-items-center">
        <div class="col-md-12 col-lg-5">
          <div class="comman-title mb-0">
            <h3>Explore by Location</h3>
          </div>
        </div>
        <div class="col-md-12 col-lg-7">
          <div class="row">
              <div class="col-md-4 col-6">                  
              <select class="form-control" title="Listing type">
                <option>Location type</option>
                <option>Location type</option>
                <option>Location type</option>
              </select>
              </div>
              <div class="col-md-4 col-6">                  
              <select class="form-control" title="Property Type">
                <option>Property Type</option>
                <option>Property Type</option>
                <option>Property Type</option>
              </select>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-md-0">
                <button type="submit" class="form-control">
                    More Filters <i class="ms-2"><img src="frontend/images/filter-ico.svg"></i>
                  </button>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 d-md-flex align-items-stretch">
          <div class="location-exp-bx">
            <div class="mark-logo top-lft">
              <img src="frontend/images/logo.png">
            </div>
            <picture>
              <img src="frontend/images/location-img1.jpg">
            </picture>
            <h5>The city name</h5>
          </div>
        </div>
        <div class="col-md-3 d-md-flex align-items-stretch">
          <div class="location-exp-bx">
            <div class="mark-logo top-lft">
              <img src="frontend/images/logo.png">
            </div>
            <picture>
              <img src="frontend/images/location-img2.jpg">
            </picture>
            <h5>The city name</h5>
          </div>
        </div>
        <div class="col-md-3 d-md-flex align-items-stretch">
          <div class="location-exp-bx">
            <div class="mark-logo top-lft">
              <img src="frontend/images/logo.png">
            </div>
            <picture>
              <img src="frontend/images/location-img3.jpg">
            </picture>
            <h5>The city name</h5>
          </div>
        </div>
        <div class="col-md-4 d-md-flex align-items-stretch">
          <div class="location-exp-bx">
            <div class="mark-logo top-lft">
              <img src="frontend/images/logo.png">
            </div>
            <picture>
              <img src="frontend/images/location-img4.jpg">
            </picture>
            <h5>The city name</h5>
          </div>
        </div>
        <div class="col-md-4 d-md-flex align-items-stretch">
          <div class="location-exp-bx">
            <div class="mark-logo top-lft">
              <img src="frontend/images/logo.png">
            </div>
            <picture>
              <img src="frontend/images/location-img5.jpg">
            </picture>
            <h5>The city name</h5>
          </div>
        </div>
        <div class="col-md-4 d-md-flex align-items-stretch">
          <div class="location-exp-bx">
            <div class="mark-logo top-lft">
              <img src="frontend/images/logo.png">
            </div>
            <picture>
              <img src="frontend/images/location-img6.jpg">
            </picture>
            <h5>The city name</h5>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="why-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-7">
          <div class="why-ser-bx">
            <div class="comman-title">
              <h3>Why PROPverse?</h3>
            </div>
            <ul>
              <li>
                <figure>
                  <img src="frontend/images/why-ico1.svg">
                </figure>
                <div class="">
                  <p class="mb-0"><b>Focus On Quality Listings & Qualified Leads, Not Search Ranking</b>: Forget expensive listing boosters with zer0-sum game of endless credit top-ups. PROPverse prioritizes properties based on relevant features, not who pays the most, and this means your listings reach serious buyers looking for exactly what you have.</p>
                </div>
              </li>
              <li>
                <figure>
                  <img src="frontend/images/why-ico2.svg">
                </figure>
                <div class="">
                  <p class="mb-0"><b>Tailored Property Matching, Every Listing Is Unique On Its Own</b>: PROPverse goes beyond basic property searches, it’s believed to be the 1stproperty listing platform in the market that incorporates the option of FengShui Mapping to unlock hidden-values of your property, and connect you with purchasers/renters seeking properties that resonate with their innate personality or needs at a deeper level - Compete on Value, not Price!</p>
                </div>
              </li>
              <li>
                <figure>
                  <img src="frontend/images/why-ico3.svg">
                </figure>
                <div class="">
                  <p class="mb-0"><b>Tech-Powered Auto-Sourcing & Enabler Tools For Success</b>: PROPverse isn't just a property listing board, it offers tools that introduces another 1st-in-market concept of Hybrid-Listing, empowering property community-inclusion and participation, collaborating with Estate Agents and breeding property bird-doggers through digital hand-shake to streamline the buying, selling and renting engagements with increasing productivity.</p>
                </div>
              </li>
            </ul>
            <div class="d-flex">
              <a href="#" class="comman-btn-2">Learn more</a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <div class="why-pic">
            <figure>
              <img src="frontend/images/why-img.jpg">
            </figure>
            <div class="mark-logo">
              <img src="frontend/images/logo.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="home-counter-sec">
    <div class="container">
      <ul>
        <li>
          <h3>456</h3>
          <span>Agent & Agency</span>
        </li>
        <li>
          <h3>1532</h3>
          <span>Happy client</span>
        </li>
        <li>
          <h3>456</h3>
          <span>awards won</span>
        </li>
        <li>
          <h3>4536</h3>
          <span>New listing every month</span>
        </li>
        <li>
          <h3>4536</h3>
          <span>New listing every month</span>
        </li>
      </ul>
    </div>
  </section>

  <section class="meet-agent-sec">
    <div class="container">
      <div class="comman-title text-center">
        <span>Our registered agents</span>
        <h3>Meet our agents</h3>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="meet-agent-bx">
            <div class="meet-pic">
              <picture>
                <img src="frontend/images/meet-img1.png">
              </picture>
              <div class="mark-logo">
                <img src="frontend/images/logo.png">
              </div>
            </div>
            <div class="agent-info">
              <h4>Ronald Richards</h4>
              <span>Real state agent</span>
              <p>Explore our extensive listings to discover a wide range of properties to suit every lifestyle and budget.</p>
            </div>
            <div class="agent-profile">
              <a href="" class="comman-btn-2">View Profile</a>
              <div class="profile-call ms-md-auto">
                <a href="">
                  <img src="frontend/images/phone-ico.svg">
                </a>
                <a href="">
                  <img src="frontend/images/email-ico.svg">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="meet-agent-bx">
            <div class="meet-pic">
              <picture>
                <img src="frontend/images/meet-img2.png">
              </picture>
              <div class="mark-logo bottom-rgt">
                <img src="frontend/images/logo.png">
              </div>
            </div>
            <div class="agent-info">
              <h4>Jenny Wilson</h4>
              <span>Real state agent</span>
              <p>Explore our extensive listings to discover a wide range of properties to suit every lifestyle and budget.</p>
            </div>
            <div class="agent-profile">
              <a href="" class="comman-btn-2">View Profile</a>
              <div class="profile-call ms-md-auto">
                <a href="">
                  <img src="frontend/images/phone-ico.svg">
                </a>
                <a href="">
                  <img src="frontend/images/email-ico.svg">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="meet-agent-bx">
            <div class="meet-pic">
              <picture>
                <img src="frontend/images/meet-img3.png">
              </picture>
              <div class="mark-logo bottom-rgt">
                <img src="frontend/images/logo.png">
              </div>
            </div>
            <div class="agent-info">
              <h4>Cameron Williamson</h4>
              <span>Real state agent</span>
              <p>Explore our extensive listings to discover a wide range of properties to suit every lifestyle and budget.</p>
            </div>
            <div class="agent-profile">
              <a href="" class="comman-btn-2">View Profile</a>
              <div class="profile-call ms-md-auto">
                <a href="">
                  <img src="frontend/images/phone-ico.svg">
                </a>
                <a href="">
                  <img src="frontend/images/email-ico.svg">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="testimonial-sec">
    <div class="container">
      <div class="comman-title text-center">
        <span>Testimonials</span>
        <h3>what our customer say about us</h3>
      </div>
      <div class="testimonail-carousel owl-carousel owl-theme">
          <div class="item">
            <div class="testimoanil-bx">
              <div class="client-header">
                <figure>
                  <img src="frontend/images/agent1.png">
                </figure>
                <div class="client-nm">
                  <h4>Jane Cooper</h4>
                  <div class="rating-show">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                </div>
              </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
            </div>
          </div>
          <div class="item">
            <div class="testimoanil-bx">
              <div class="client-header">
                <figure>
                  <img src="frontend/images/agent2.jpg">
                </figure>
                <div class="client-nm">
                  <h4>Ralph Edwards</h4>
                  <div class="rating-show">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                </div>
              </div>
            <div class="testimoanil-content">
                <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
            </div>
          </div>
          <div class="item">
            <div class="testimoanil-bx">
              <div class="client-header">
                <figure>
                  <img src="frontend/images/agent3.png">
                </figure>
                <div class="client-nm">
                  <h4>Brooklyn Simmons</h4>
                  <div class="rating-show">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                </div>
              </div>
            <div class="testimoanil-content">
                <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
            </div>
          </div>            
          <div class="item">
            <div class="testimoanil-bx">
              <div class="client-header">
                <figure>
                  <img src="frontend/images/agent1.png">
                </figure>
                <div class="client-nm">
                  <h4>Jane Cooper</h4>
                  <div class="rating-show">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                </div>
              </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
            </div>
          </div>
          <div class="item">
            <div class="testimoanil-bx">
              <div class="client-header">
                <figure>
                  <img src="frontend/images/agent2.jpg">
                </figure>
                <div class="client-nm">
                  <h4>Ralph Edwards</h4>
                  <div class="rating-show">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                </div>
              </div>
            <div class="testimoanil-content">
                <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
            </div>
          </div>
          <div class="item">
            <div class="testimoanil-bx">
              <div class="client-header">
                <figure>
                  <img src="frontend/images/agent3.png">
                </figure>
                <div class="client-nm">
                  <h4>Brooklyn Simmons</h4>
                  <div class="rating-show">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                </div>
              </div>
            <div class="testimoanil-content">
                <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
            </div>
          </div>            
      </div>
    </div>
  </section>

</template>

<script>

// import $ from 'jquery'; // Import jQuery
export default {
  name: 'HomePage',
  mounted() {

      window.$(document).ready(function(){
        window.$('.property-carousel').owlCarousel({
            loop: false,
            margin: 0,
            nav: true,
            dots: true,
            navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
            responsive: {
              0: {
                items: 1,
                margin: 0
              },
              600: {
                items: 1
              },
              1000: {
                items: 1
              }
            }
          });

          // Initialize pro type carousel
          window.$('.pro-type-carousel').owlCarousel({
            loop: false,
            margin: 20,
            nav: true,
            dots: false,
            navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
            responsive: {
              0: {
                items: 1,
                margin: 10
              },
              600: {
                items: 2
              },
              1000: {
                items: 4
              }
            }
          });

          // Initialize testimonial carousel
          window.$('.testimonail-carousel').owlCarousel({
            loop: false,
            margin: 25,
            nav: false,
            dots: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 3
              }
            }
          });     
    });
  }
}
</script>

<style scoped>
/* Add styles specific to the Home page here */
</style>
