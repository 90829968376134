<template>    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>2/6</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><a href="\post-property-listing">Step 1</a></li>
                            <li><a class="active" href="javascript:;">Step 2</a></li>
                            <li><a href="javascript:;">Step 3</a></li>
                            <li><a href="javascript:;">Step 4</a></li>
                            <li><a href="javascript:;">Step 5</a></li>
                            
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="property-box">
                        <form @submit.prevent="submitPost">  
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Property Details</h3>
                                </div>								
                                <div class="row row-cols-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Features</label>
                                            <select id="selectedFeatures" v-model="form.selectedFeatures" class="form-control" multiple>
                                                <option value="" disabled selected>Please Features</option>
                                                <option v-for="option in optionsFeatures" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span class="info">*Can be selected multiple options</span>
                                        </div>
                                    </div>                               
                                    <div class="col">
                                        <div class="form-group">
                                            <label></label>
                                            <button class="btn comman-btn-2 w-100" data-bs-toggle="modal" data-bs-target="#add-feature-modal">Add Features <img src="frontend/images/plus-circal.svg" alt=""></button>
                                        </div>
                                    </div> 									                                 
                                </div>
                                <div class="row row-cols-2">
                                    
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Facilities</label>
                                            <select id="selectedFacilities" v-model="form.selectedFacilities" class="form-control" multiple>
                                                <option value="" disabled selected>Please Facilities</option>
                                                <option v-for="option in optionsFacilities" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span class="info">*Can be selected multiple options</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label></label>
                                            <button class="btn comman-btn-2 w-100" data-bs-toggle="modal" data-bs-target="#add-facility-modal">Add Facilities <img src="frontend/images/plus-circal.svg" alt=""></button>
                                        </div>
                                    </div> 									                                 
                                </div>								
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Furnishing & Fittings</label>
                                        <div v-for="item in checkboxFFlist" :key="item.id"  class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" 
                                            :id="item.id"
                                            :value="item.id"
                                            v-model="checkboxFF">
                                            <label class="form-check-label w-100" :for="item.id">
                                                {{ item.name }}
                                            </label>
                                        </div>	
                                        
                                    </div>
                                    <div class="form-group ai-textarea">
                                        <label>Description</label>
                                        <textarea class="form-control" placeholder="Description" rows="5">Write an attention-grabbing description about this property listing, highlight its unique features or hidden-value, it must be exciting for potential buyers or renters to want to enquire more and contact me. Include some nearby amenities and neighbourhood details, convenience and perks that home-buyers would love about it. Emphasize on the advantages of the property and present it in a professional format, but make it brief and sounds interesting!</textarea>
                                        <span> 1,000 words</span>
                                    </div>
                                </div>
                            </div> 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Pricing Terms & Payment Options <i class="fa fa-question-circle"></i></h3>
                                </div>								
                                <div class="form-group">
                                    <label>Asking Sale Price</label>
                                    <input type="text" class="form-control" placeholder="Asking Sale Price">											
                                </div>								
                                <div class="form-group">
                                    <label for="">Payment Mode Acceptance</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="" id="Cheque-Bank-Transfer">
                                        <label class="form-check-label w-100" for="Cheque-Bank-Transfer">
                                            Cheque/Bank Transfer
                                        </label>
                                    </div>	
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="" id="Debit-Credit-Card">
                                        <label class="form-check-label w-100" for="Debit-Credit-Card">
                                            Debit/Credit Card
                                        </label>
                                    </div>	
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="" id="eWallet">
                                        <label class="form-check-label w-100" for="eWallet">
                                            eWallet
                                        </label>
                                    </div>	
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="" id="Digital-Currency">
                                        <label class="form-check-label w-100" for="Digital-Currency">
                                            Digital Currency
                                        </label>
                                    </div>	
                                </div>														
                            </div> 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Property Status</h3>
                                </div>				
                                <div class="row row-cols-1 row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Current Occupancy</label>
                                            <select class="form-control">
                                                <option>Current Occupancy</option>
                                                <option>Occupied By Owner</option>
                                                <option>Rented To Tenant</option>
                                                <option>Vacant</option>
                                                <option>Renovation / Make-Over In Progress</option>
                                            </select>
                                        </div>	
                                    </div>									
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Current Monthly Rental</label>
                                            <input type="text" class="form-control" placeholder="Current Monthly Rental">
                                        </div>	
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Current Tenancy Expiry</label>
                                            <input type="date" class="form-control" placeholder="Current Tenancy Expiry">
                                        </div>	
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="">Availability Date</label>
                                            <input type="date" class="form-control" placeholder="Availability Date">
                                        </div>	
                                    </div>
                                </div>														
                            </div> 
                            <div class="d-flex justify-content-md-end">
                                <!-- <button class="btn comman-btn-6">Previous</button> -->
                                <router-link :to="`/post-property-listing/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                                <button class="btn comman-btn-2 ms-3" type="submit">Next</button>
                            </div>
                        </form>  
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="add-feature-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">                           
                       
                        <div class="comman-title">
                            <h3>Add Features</h3>
                        </div>
                        <form @submit.prevent="submitAdditionalFF">
                            <div class="row row-cols-md-1">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Additional feature name</label>
                                        <input class="form-control" type="text" v-model="form.additional_feature_name" placeholder="Additional feature name">
                                        <span  class="error" v-if="errors.additional_feature_name">{{ errors.additional_feature_name }}</span>
                                    </div>
                                </div>                                   
                            </div> 
                            <button class="btn comman-btn-2" type="submit">Save</button>   
                        </form>                       
                        
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="add-facility-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="images/close.svg" alt=""></button>			
                    <div class="modal-body">                           
                       
                        <div class="comman-title">
                            <h3>Add facility</h3>
                        </div>
                        <form @submit.prevent="submitAdditionalFacility">
                            <div class="row row-cols-md-1">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Additional facility name</label>
                                        <input class="form-control" type="text" v-model="form.additional_facility_name" placeholder="Additional facility name">
                                        <span  class="error" v-if="errors.additional_facility_name">{{ errors.additional_facility_name }}</span>
                                    </div>
                                </div>                                   
                            </div> 
                            <button class="btn comman-btn-2" type="submit">Save</button>   
                        </form>                       
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import { OPTIONS_GROUP_1 } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  import $ from 'jquery';
  export default {
    name: 'PostPropertyListingStep2', 
    data() {
        return {
      
            optionsFeatures: [],
            optionsFacilities: [],
            checkboxFFlist: [],

            optionsGroup1: OPTIONS_GROUP_1,
            
            form: {
                selectedFeatures: '',
                selectedFacilities: '',
                checkedFF: '',
                property_name: '',
                qualify_for_foreigner: '0',
                listingType: ''
            },            
            errors: {}
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
      itemId() {
        return this.$route.params.id;
      }
    },
    created() {
        this.fetchOptions();
    },
    methods: {

        validateForm() {
            console.log(this.form.listingType);
            this.errors = {};
            if (!this.form.property_name) {
                this.errors.property_name = 'This field is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        validateAddionalFF() {            
            this.errors = {};
            if (!this.form.additional_feature_name) {
                this.errors.additional_feature_name = 'This field is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        validateAddionalFacility() {            
            this.errors = {};
            if (!this.form.additional_facility_name) {
                this.errors.additional_facility_name = 'This field is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            this.$router.push('/post-property-listing-3/'+this.itemId);         
            if (this.validateForm()) {
                var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    
                    const response = await this.$axios.post('api/add-update-property', {
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:0,
                        country_id: 93, 
                        listing_type: "2",
                        qualify_for_foreigner:qualify_for_foreigner,
                        property_name:this.form.property_name,
                        street:this.form.street,
                        area_hdb_estate:this.form.area_hdb_estate,
                        district:this.form.district,
                        block_no:this.form.block_no,
                        level_no:this.form.level_no,
                        unit_no:this.form.unit_no,
                        property_type:this.selectedPropertyType,
                        land_tenure:this.form.land_tenure,
                        floor_area:this.form.floor_area,
                        floor_area_unit:this.form.floor_area_unit,
                        land_area:this.form.land_area,
                        land_area_unit:this.form.land_area_unit,
                        land_title_type:this.form.land_title_type,
                        developer_name:this.form.developer_name,
                        total_blocks:this.form.total_blocks,
                        total_units:this.form.total_units,
                        total_car_parks:this.form.total_car_parks,
                        year_of_competion_TOP:this.form.year_of_competion_TOP,
                        no_of_rooms:this.form.no_of_rooms,
                        no_of_baths:this.form.no_of_baths,
                        no_car_park_lots:this.form.no_car_park_lots,
                        layout_type_id:this.form.layout_type_id,
                        floor_level_id:this.form.floor_level_id,
                        main_view_id:this.form.main_view_id
                    });
                    
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    // console.log('Login successful:', response.data);
                    // console.log('id of posted', response.data.output.id);
                    var property_listing_id = response.data.output.id
                    this.$router.push('/post-property-listing-2/'+property_listing_id);         

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },  

        async fetchOptions() {

            // Get Features list
            try {
                const response = await this.$axios.post('api/pr-master-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFeatures = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get facilities list
            try {
                const response = await this.$axios.post('api/pr-master-facilities-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFacilities = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Furnishing & Fittings
            try {
                const response = await this.$axios.post('api/pr-master-furnishing-fittings-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_checkbox: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.checkboxFFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                console.log(this.checkboxFFlist);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        },

        async submitAdditionalFF() {
            
            if (this.validateAddionalFF()) {
                try {
                    const token = localStorage.getItem('userToken');
                    await this.$axios.post('api/add-property-listing-features-additionals', {
                                user_id:this.user.id,
                                api_token:token,                    
                                property_listing_id: this.itemId,
                                additional_feature_name: this.form.additional_feature_name
                    });
                    toastr.success('Featured added', 'Success', { timeOut: 1000 });
                    setTimeout(function(){
                        $(".btn-close").trigger('click');
                    },500);
                } catch (error) {
                            
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        
        async submitAdditionalFacility() {
            
            if (this.validateAddionalFacility()) {
                try {
                    const token = localStorage.getItem('userToken');
                    await this.$axios.post('api/add-property-listing-facilities-additionals', {
                                user_id:this.user.id,
                                api_token:token,                    
                                property_listing_id: this.itemId,
                                additional_facility_name: this.form.additional_facility_name
                    });
                    toastr.success('Facility added', 'Success', { timeOut: 1000 });
                    setTimeout(function(){
                        $(".btn-close").trigger('click');
                    },500);
                } catch (error) {
                            
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        }

      
    }   
    
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  