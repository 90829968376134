<template>
  
    <template v-for="option in options" :key="option.id">
      <template v-if="option.type === 'optgroup'">
        <optgroup :label="`${option.depth} ${option.label}`" :class="getOptionClass(option.depth)">
        </optgroup>
        <RecursiveDropdown :options="option.children" :class="getOptionClass(option.depth)"  />
      </template>
      <template v-else>
        <option :value="option.id">{{ option.depth }} {{ option.label }}</option>
      </template>
    </template>
  
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    getOptionClass(depth) {
      console.log(`indent-${depth || 0}`);
      // Return a class based on the option depth or type
      return `indent-${depth || 0}`;
    },
    // existing methods...
  }
};
</script>

<style scoped>
select optgroup[label] {
  border-bottom: 1px solid #ddd; /* Add a separator line if needed */
  padding-bottom: 5px; /* Adjust space below the label */
  margin-bottom: 5px; /* Adjust space below the label */
}
select optgroup > option {
  padding-left: 20px; /* Adjust as needed */
}
/* Indentation styles */
.indent-0 {
  padding-left: 0px;
}

.indent-1 {
  padding-left: 40px;
}

.indent-2 {
  padding-left: 60px;
  margin-left: 60px;
}

/* Add more classes as needed */
</style>
