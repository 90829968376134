<template>    
  <section class="signin-sec">
    <div class="container">
      <div class="signin-bx m-auto">
        <h1>Login</h1>
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
        <form @submit.prevent="login">
          <div class="form-group">
            <input
              type="email"
              placeholder="Email address"
              class="form-control"
              v-model="email"
              @blur="validateEmail"
              :class="{ 'is-invalid': emailError }"
            />
            <div v-if="emailError" class="error">{{ emailError }}</div>
          </div>
          <div class="form-group">
            <input
              type="password"
              placeholder="Password"
              class="form-control"
              v-model="password"
              @blur="validatePassword"
              :class="{ 'is-invalid': passwordError }"
            />
            <div v-if="passwordError" class="error">{{ passwordError }}</div>
          </div>
          <div class="d-flex justify-content-between form-group">							
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Remember me
              </label>
            </div>							
            <span class="forgot-link ml-auto"><a href="forgotpassword.html">Forgot Password?</a></span>
          </div>
          <div class="form-group">
            <button type="submit" class="btn comman-btn-2 w-100" :disabled="loading">Login</button>              
          </div>
          <div class="or-text"><span>Or continue with</span></div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6 col-6">
                <div class="login-link">
                  <a href=""><i><img src="frontend/images/google-login.png"></i>Google</a>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <div class="login-link">
                  <a href=""><i><img src="frontend/images/fb-login.png"></i>Facebook</a>
                </div>
              </div>
            </div>
          </div>
          <small class="no-login">
            By continuing up, you agree to PROPverse <br><a href="">Terms and Conditions</a> & <a href="">Privacy Policy.</a>
          </small>
          <small class="no-login mb-0">
            Not a member? <a href="/sign-up"><b>Create account</b></a>
          </small>
        </form>
      </div>
    </div>
  </section>	
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'SignIn',
  data() {
    return {
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      errorMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapActions(['setAuthenticated', 'setUser']),
    isFormValid() {
      return !this.emailError && !this.passwordError && this.email && this.password;
    },
  },
  methods: {
    validateEmail() {
      this.emailError = this.email
        ? /\S+@\S+\.\S+/.test(this.email)
          ? ''
          : 'Invalid email format'
        : 'Email is required';
    },
    validatePassword() {
      this.passwordError = this.password
        ? this.password.length >= 6
          ? ''
          : 'Password must be at least 6 characters long'
        : 'Password is required';
    },
    
    async login() {
      this.validateEmail();
      this.validatePassword();
      
      if (this.emailError || this.passwordError) {
        return; // Prevent form submission if there are validation errors
      }

      if (this.loading) return;
        this.loading = true;
        try {
          // Use the globally configured Axios instance
          const response = await this.$axios.post('api/login', {
            email: this.email,
            password: this.password
          });

          console.log('Login successful:', response.data);
          await this.$store.dispatch('login', response.data);
          this.$router.push('/profile'); 

        } catch (error) {
          // Handle login errors
          this.errorMessage = 'Login failed. Please check your credentials.';
          console.error('Login error:', error);
        } finally {
          this.loading = false;
        }
    }    
  }
}
</script>