<template>
    <section class="signin-sec">
        <div class="container">
            <div class="step-process-outer">
                <ol>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico1.svg">
                        </figure>	
                        <p class="mb-0">Just register for FREE Sign-Up & start listing your properties.</p>
                    </li>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico2.svg">
                        </figure>	
                        <p class="mb-0">Decide on options to upgrade & select packages according to your needs.</p>
                    </li>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico3.svg">
                        </figure>	
                        <p class="mb-0">Complete your profile & payment to access exclusive tools & privileges.</p>
                    </li>
                    <li>
                        <figure>
                            <img src="frontend/images/signup-step-ico4.svg">
                        </figure>	
                        <p class="mb-0">complete your profile with required data and verification.</p>
                    </li>
                </ol>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-6 d-md-flex align-items-stretch">
                    <div class="private-why-outr">
                        <div class="comman-title">
                            <h3>Why Sign Up As Private User?</h3>
                            <p>Private User (Property Owner-Landlord / Buyer & Renter-Tenant)</p>
                        </div>
                        <ol>
                            <li>
                                <div class="">
                                    <h4>Own Your Listing, Amplify Its Reach;</h4>
                                    <p>You list the property, own it like your story, take advantage of our features to connect at a deeper level with potential buyers or renters, or empower estate agents to reach a wider audience.</p>
                                </div>
                            </li>
                            <li>
                                <div class="">
                                    <h4>Maintain Control & Flexibility;</h4>
                                    <p>View the listing updates yourself, decide on the level of involvement that suits you- DIY to save on commissions but let estate agents handle all the heavy-lifting in a supporting role.</p>
                                </div>	
                            </li>
                            <li>
                                <div class="">
                                    <h4>Expert Marketing, Local Insights;</h4>
                                    <p>Partner with qualified estate agents to benefit from their client network, marketing expertise, negotiation skills, local knowledge and maximize prospective offers.</p>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 d-md-flex align-items-stretch">
                    <div class="signin-bx full-width">
                        <h1>Sign up</h1>
                        <form @submit.prevent="register">
                            <div class="form-group">
                                <input v-model="form.name" id="name" type="text" class="form-control" placeholder="Name" />
                                <span  class="error" v-if="errors.name">{{ errors.name }}</span>
                            </div>
                            <div class="form-group">
                                <input v-model="form.email" id="email" type="text" class="form-control" placeholder="Email address" />
                                <span  class="error" v-if="errors.email">{{ errors.email }}</span>
                            </div>
                            <div class="form-group">
                                <input v-model="form.mobile_number" id="mobile_number" type="text" class="form-control" placeholder="Phone Number" />
                                <span  class="error" v-if="errors.mobile_number">{{ errors.mobile_number }}</span>
                            </div>                            
                            <div class="form-group">
                                <input v-model="form.password" id="password" type="password" class="form-control" placeholder="Password" />
                                <span  class="error" v-if="errors.password">{{ errors.password }}</span>
                            </div>                            
                            <div class="form-group">
                                <input v-model="form.confirmPassword" id="confirmPassword" type="password" class="form-control" placeholder="Repeat password" />
                                <span  class="error" v-if="errors.confirmPassword">{{ errors.confirmPassword }}</span>
                            </div>
                            
                            <div class="d-flex form-group align-items-center">
                                <div class="signup-othr">
                                    <a href="/sign-up-business">Sign up as Business/Corporate</a>
                                </div>
                                <small class="no-login ms-auto text-end m-0">
                                    Have account?  <a href="sign-in"><b>Log in</b></a>
                                </small>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn comman-btn-2 w-100">Sign Up</button>
                            </div>
                            <div class="or-text"><span>Or continue with</span></div>																
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="login-link">
                                            <a href=""><i><img src="frontend/images/google-login.png"></i>Google</a>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="login-link">
                                            <a href=""><i><img src="frontend/images/fb-login.png"></i>Facebook</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <img src="frontend/images/capcha-2.png" alt="">
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="otr-foen-ta-1">
                                <label class="form-check-label w-100" for="otr-foen-ta-1">										
                                    By continuing up, you agree to PROPverse <br><a href="">Terms and Conditions</a> & <a href="">Privacy Policy.</a>
                                </label>
                            </div>	
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>
  
  <script>
  import { mapActions } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
 
  export default {
    name: 'SignUp',
    data() {
        
        return {
        form: {
            name: '',
            email: '',
            mobile_number: '',
            password: '',
            confirmPassword: '',
        },
        errors: {}
        };
    },
    computed: {
      ...mapActions(['setAuthenticated', 'setUser']),
      isFormValid() {
        return !this.emailError && !this.passwordError && this.email && this.password;
      },
    },
    methods: {
      validateForm() {
        this.errors = {};

        if (!this.form.name) {
            this.errors.name = 'Name is required.';
        }
        if (!this.form.mobile_number) {
            this.errors.mobile_number = 'Phone number is required.';
        }

        if (!this.form.email) {
            this.errors.email = 'Email is required.';
        } else if (!this.validEmail(this.form.email)) {
            this.errors.email = 'Invalid email address.';
        }

        if (!this.form.password) {
            this.errors.password = 'Password is required.';
        } else if (this.form.password.length < 6) {
            this.errors.password = 'Password must be at least 6 characters long.';
        }

        if (!this.form.confirmPassword) {
            this.errors.confirmPassword = 'Confirm password is required.';
        } else if (this.form.password !== this.form.confirmPassword) {
            this.errors.confirmPassword = 'Passwords do not match.';
        }

        return Object.keys(this.errors).length === 0;
        },
        validEmail(email) {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);

      },
      
      async register() {
        
        // this.validateEmail();
        // this.validatePassword();

        if (this.validateForm()) {
            if (this.emailError || this.passwordError) {
            return; // Prevent form submission if there are validation errors
            }
    
            if (this.loading) return;
            this.loading = true;
            try {
                // Use the globally configured Axios instance
                const response = await this.$axios.post('api/register', {
                    name: this.form.name,
                    email: this.form.email,
                    password: this.form.password,                    
                    mobile_isd_code: '91',
                    mobile_number: this.form.mobile_number,
                    is_business: '0',
                    is_corporate: '0',
                    is_private_user: '1',
                    business_name: ''
                });
                
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                const response_login = await this.$axios.post('api/login', {
                    email: this.form.email,
                    password: this.form.password
                });

                console.log('Login successful:', response_login.data);
                await this.$store.dispatch('login', response_login.data);
                // console.log('Register successful:', response.data);
                // await this.$store.dispatch('login', response.data);
                this.$router.push('/verify-number'); 
    
            } catch (error) {
                
                if (error.response && error.response.data) {
                    // Extract and set errors from the response
                    this.errors = this.extractErrors(error.response.data.errors || {});
                } else {
                    // Handle unexpected errors
                    console.error('An unexpected error occurred:', error);
                }
                // Handle login errors
                //this.errorMessage = 'Login failed. Please check your credentials.';
                //console.error('Login error:', error);
            } finally {
                this.loading = false;
            }
        }
      },
      extractErrors(errors) {
        // Extract the first error message from each field's errors array
        const formattedErrors = {};
        for (const [field, messages] of Object.entries(errors)) {
            if (Array.isArray(messages) && messages.length > 0) {
            formattedErrors[field] = messages[0]; // Get the first error message
            }
        }
        return formattedErrors;
      }    
    }
  }
  </script>