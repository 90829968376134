import axios from 'axios';


// Create an instance of Axios with default settings
const instance = axios.create({
  baseURL: 'https://pv-staging.tess.gg/public/', // Base URL for your API
});

// Function to get the auth token
async function getAuthToken() {
  // Check if the token is already in localStorage
  const token = localStorage.getItem('authToken');
  
  if (token) {
    return token;
  }

  // If not, fetch the token
  try {
    const response = await axios.post('/public/oauth/token', {
      grant_type: 'client_credentials', // Adjust as needed
      client_id: '1',
      client_secret: 'czI1IMgcxFLjD0JzwJWzIutDC8FB3NBnA0OVrORA',
    });
    
    //const { token: newToken } = response.data.access_token; // Adjust based on the response structure
    const newToken  = response.data.access_token; // Adjust based on the response structure
    console.log(newToken);
    // Store the token
    localStorage.setItem('authToken', newToken);

    return newToken;
  } catch (error) {
    console.error('Failed to fetch auth token:', error);
    throw error;
  }
}

// Add a request interceptor to add the Authorization header
instance.interceptors.request.use(
  async config => {
    const token = await getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      //config.headers.common['Access-Control-Allow-Origin'] = '*';
    }
    return config;
  },
  error => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors
instance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized access - possible token expiration or invalid token');
      localStorage.removeItem('authToken');
     // window.location.href = '/sign-in';
    }
    return Promise.reject(error);
  }
);

export default instance;
