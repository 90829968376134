import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';  // Import your Vuex store
import axios from './axios'; // Import your configured Axios instance

const app = createApp(App);

// Make Axios available globally
app.config.globalProperties.$axios = axios;

// Use the Vuex store
app.use(store);

// Use the router
app.use(router);

// Mount the application
app.mount('#app');

