<template>    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>1/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><a class="active" href="\post-property-listing">Step 1</a></li>
                            <li><a href="\post-property-listing-2">Step 2</a></li>
                            <li><a href="03-create-listing.html">Step 3</a></li>
                            <li><a href="04-create-listing.html">Step 4</a></li>
                            <li><a href="05-create-listing.html">Step 5</a></li>								
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box">
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Key Information</h3>
                                </div>
                                <div class="form-group">
                                    <label>Listing Type</label>
                                    <select 
                                        id="group1Select" 
                                        class="form-control"
                                        v-model="form.listingType" 
                                    >
                                    <option value="" selected>Listing Type</option>
                                        <option 
                                        v-for="option in optionsGroup1" 
                                        :key="option.value" 
                                        :value="option.value"
                                        >
                                        {{ option.label }}
                                        </option>
                                    </select>
                                    <span  class="error" v-if="errors.listingType">{{ errors.listingType }}</span>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" v-model="form.qualify_for_foreigner" id="flexCheckDefault9">
                                    <label class="form-check-label w-100" for="flexCheckDefault9">
                                        Qualify For Foreigner
                                    </label>
                                </div>	
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Project Address / Location</h3>
                                </div>
                                <div class="form-group">
                                    <label>Property/Place/Project Name</label>
                                    <input type="text" class="form-control" v-model="form.property_name" placeholder="Property/Place/Project Name">
                                    <span  class="error" v-if="errors.property_name">{{ errors.property_name }}</span>
                                </div>
                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Street</label>
                                            <input type="text" class="form-control" v-model="form.street" placeholder="Street">
                                            <span  class="error" v-if="errors.street">{{ errors.street }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Area / HDB Estate</label>
                                            <input type="number" min="0" class="form-control"  v-model="form.area_hdb_estate" placeholder="Area / HDB Estate">
                                            <span  class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>District</label>
                                            <input type="text" class="form-control"  v-model="form.district" placeholder="District">
                                            <span  class="error" v-if="errors.district">{{ errors.district }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Block No</label>
                                            <input type="number" min="0" class="form-control"  v-model="form.block_no" placeholder="Block No">
                                            <span  class="error" v-if="errors.block_no">{{ errors.block_no }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Level No.</label>
                                            <input type="number" min="0" class="form-control" v-model="form.level_no" placeholder="Level No.">
                                            <span  class="error" v-if="errors.level_no">{{ errors.level_no }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Unit No.</label>
                                            <input type="number" min="0" class="form-control"  v-model="form.unit_no" placeholder="Unit No.">
                                            <span  class="error" v-if="errors.unit_no">{{ errors.unit_no }}</span>
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Basic Information</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Property Type</label>
                                            <select class="form-control" v-model="form.selectedPropertyType">
                                                <option value="" disabled>Select Property Type</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.selectedPropertyType">{{ errors.selectedPropertyType }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Land Tenure</label>
                                            <select id="land_tenure" v-model="form.land_tenure" class="form-control">
                                                <option value="" disabled selected>Land Tenure</option>
                                                <option v-for="option in optionsLandTenuresList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group row">
                                            <label>Floor Area (SQF/SQM)</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.floor_area" placeholder="Floor Area">
                                            <select class="form-control col" v-model="form.floor_area_unit">
                                                <option value="SQF">SQF</option>
                                                <option value="SQM">SQM</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group row">
                                            <label>Land Area (For Landed ONLY)</label>
                                            <input type="number" min="0" class="form-control col" v-model="form.land_area" placeholder="Floor Area">
                                            <select class="form-control col" v-model="form.land_area_unit">
                                                <option value="SQF">SQF</option>
                                                <option value="SQM">SQM</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col">
                                        <div class="form-group">
                                            <label>Land Area (For Landed ONLY)</label>
                                            <input type="text" class="form-control"  placeholder="Land Area (For Landed ONLY)">
                                        </div>
                                    </div> -->

                                        
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Land Title Type</label>
                                            <select class="form-control col" v-model="form.land_title_type">
                                                <option value="" selected> Land Title Type</option>
                                                <option value="HDB">HDB</option>
                                                <option value="Strata">Strata</option>
                                                <option value="Landed">Landed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Developer</label>
                                            <input type="text" class="form-control" v-model="form.developer_name" placeholder="Developer">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total Blocks</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_blocks" placeholder="Total Blocks">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total Units</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_units" placeholder="Total Units">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total carpark Lots</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_car_parks" placeholder="Total carpark Lots">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Year of Completion</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.year_of_competion_TOP" placeholder="Year of Completion">
                                        </div>
                                    </div>


                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Configuration</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Rooms</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.no_of_rooms"  placeholder="Rooms">											 
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Baths</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.no_of_baths"  placeholder="Baths">											 
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Car Park Lots</label>
                                            <input type="number" min="0" class="form-control"   v-model="form.no_car_park_lots"  placeholder="Car Park Lots">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Layout Type</label>
                                            <select id="layout_type_id" v-model="form.layout_type_id" class="form-control">
                                                <option value="" disabled selected>Layout Type</option>
                                                <option v-for="option in optionsLayoutTypeList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Floor Level</label>
                                            <select id="floor_level_id" v-model="form.floor_level_id" class="form-control">
                                                <option value="" disabled selected>Floor Level</option>
                                                <option v-for="option in optionsFloorLevelList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Main View</label>
                                            <select class="form-control"  v-model="form.main_view_id">
                                                <!-- <option>Main View</option> -->
                                                <option selected>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="d-flex justify-content-md-end">
                                <button class="btn comman-btn-6">Cancel</button>
                                <button class="btn comman-btn-2 ms-3" type="submit">Next</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { OPTIONS_GROUP_1 } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  export default {
    name: 'PostPropertyListing', 
    components: {
      RecursiveDropdown
    },  
    data() {
        return {
            optionsGroup1: OPTIONS_GROUP_1,
            optionsLandTenuresList: [],
            optionsLayoutTypeList: [],
            optionsFloorLevelList: [],

            form: {
                property_name: '',
                qualify_for_foreigner: '0',
                listingType: '',
                land_tenure: '',
                layout_type_id: '',
                floor_level_id: '',
                land_title_type: '',
                main_view_id: 1,
                selectedPropertyType: '',
                floor_area_unit: 'SQF',
                land_area_unit: 'SQF',
            },
            options: this.transformData([

                { id: 1,depth:'', name: 'Residential', parent_id: 0 },

                    { id: 3,depth:'-', name: 'All HDB', parent_id: 1 },

                        { id: 6,depth:'--', name: 'HDB Flat', parent_id: 3 },
                        { id: 7,depth:'--', name: 'HDB Executive', parent_id: 3 },

                    { id: 4,depth:'-', name: 'All Non-Landed (Private)', parent_id: 1 },
                        { id: 8,depth:'--', name: 'Apartment', parent_id: 4 },
                        { id: 9,depth:'--', name: 'Walk-Up Apartment', parent_id: 4 },
                        { id: 10,depth:'--', name: 'Condominium', parent_id: 4 },
                        { id: 11,depth:'--', name: 'Executive Condominium', parent_id: 4 },

                    { id: 5,depth:'-', name: 'All Landed', parent_id: 1 },
                        { id: 12,depth:'--', name: 'Terrace / Link House', parent_id: 5 },
                        { id: 13,depth:'--', name: 'Town House', parent_id: 5 },
                        { id: 14,depth:'--', name: 'Cluster House', parent_id: 5 },
                        { id: 15,depth:'--', name: 'Conservative House', parent_id: 5 },
                        { id: 16,depth:'--', name: 'Semi-Detached/Zero-Lot', parent_id: 5 },
                        { id: 17,depth:'--', name: 'Detached/Bungalow', parent_id: 5 },
                        { id: 18,depth:'--', name: 'Good Class Bungalow (GCB)', parent_id: 5 },
                        { id: 19,depth:'--', name: 'Vacant Land', parent_id: 5 },

                { id: 2,depth:'', name: 'Commercial & Industrial', parent_id: 0 },

                    { id: 20,depth:'-', name: 'All Commercial', parent_id: 2 },
                        { id: 22,depth:'--', name: 'Retail', parent_id: 20 },
                        { id: 23,depth:'--', name: 'Office', parent_id: 20 },
                        { id: 24,depth:'--', name: 'Hotel / Hostel', parent_id: 20 },
                        { id: 25,depth:'--', name: 'Shopping Mall', parent_id: 20 },
                        { id: 26,depth:'--', name: 'Medical Suite', parent_id: 20 },
                        { id: 27,depth:'--', name: 'ShopHouse', parent_id: 20 },
                        { id: 28,depth:'--', name: 'HDB ShopHouse', parent_id: 20 },
                        { id: 29,depth:'--', name: 'Vacant Land', parent_id: 20 },
                    { id: 21,depth:'-', name: 'All Industrial', parent_id: 2 },
                        { id: 30,depth:'--', name: 'Office', parent_id: 21 },
                        { id: 31,depth:'--', name: 'Warehouse (B1)', parent_id: 21 },
                        { id: 32,depth:'--', name: 'Factory (B2)', parent_id: 21 },
                        { id: 33,depth:'--', name: 'Vacant Land', parent_id: 21 }
            ]),
            errors: {}
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
      hierarchicalOptions() {        
        return this.createHierarchicalOptions(this.options);
      }
    },
    created() {
        this.fetchOptions();
    },
    methods: {

        validateForm() {
            console.log(this.form.listingType);
            
            
            
            this.errors = {};
            if (!this.form.listingType ) {
                this.errors.listingType = 'Listing Type is required.';
            }
            if (!this.form.property_name ) {
                this.errors.property_name = 'Property Name is required.';
            }
            if (!this.form.street ) {
                this.errors.street = 'Street is required.';
            }
            if (!this.form.area_hdb_estate ) {
                this.errors.area_hdb_estate = 'Area / HDB Estate.';
            }
            if (!this.form.district ) {
                this.errors.district = 'District is required.';
            }
            if (!this.form.block_no ) {
                this.errors.block_no = 'Block no is required.';
            }
            if (!this.form.level_no ) {
                this.errors.level_no = 'Level No is required.';
            }
            if (!this.form.unit_no ) {
                this.errors.unit_no = 'Unit No is required.';
            }
            if (!this.form.selectedPropertyType ) {
                this.errors.selectedPropertyType = 'Property type is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            if (this.validateForm()) {
                var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    
                    const response = await this.$axios.post('api/add-update-property', {
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:0,
                        country_id: 93, 
                        listing_type: "2",
                        qualify_for_foreigner:qualify_for_foreigner,
                        property_name:this.form.property_name,
                        street:this.form.street,
                        area_hdb_estate:this.form.area_hdb_estate,
                        district:this.form.district,
                        block_no:this.form.block_no,
                        level_no:this.form.level_no,
                        unit_no:this.form.unit_no,
                        property_type:this.form.selectedPropertyType,
                        land_tenure:this.form.land_tenure,
                        floor_area:this.form.floor_area,
                        floor_area_unit:this.form.floor_area_unit,
                        land_area:this.form.land_area,
                        land_area_unit:this.form.land_area_unit,
                        land_title_type:this.form.land_title_type,
                        developer_name:this.form.developer_name,
                        total_blocks:this.form.total_blocks,
                        total_units:this.form.total_units,
                        total_car_parks:this.form.total_car_parks,
                        year_of_competion_TOP:this.form.year_of_competion_TOP,
                        no_of_rooms:this.form.no_of_rooms,
                        no_of_baths:this.form.no_of_baths,
                        no_car_park_lots:this.form.no_car_park_lots,
                        layout_type_id:this.form.layout_type_id,
                        floor_level_id:this.form.floor_level_id,
                        main_view_id:this.form.main_view_id
                    });
                    
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    // console.log('Login successful:', response.data);
                    // console.log('id of posted', response.data.output.id);
                    var property_listing_id = response.data.output.id
                    this.$router.push('/post-property-listing-2/'+property_listing_id);         

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },  

        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },

        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },
        createOptionGroup(option) {
            if (!option.children || option.children.length === 0) {
            return {
                type: 'option',
                id: option.id,
                label: option.name,
                depth: option.depth,
            };
            }
            return {
            type: 'optgroup',
            label: option.name,
            depth: option.depth,
            children: option.children.map(child => this.createOptionGroup(child))
            };
        },
        async fetchOptions() {

            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Layout type list
            try {
                const response = await this.$axios.post('api/pr-layout-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLayoutTypeList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Floor level
            try {
                const response = await this.$axios.post('api/pr-floor-levels-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFloorLevelList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        }
    }
  }
  </script>
  
  <style scoped>

    input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    }

    /* Remove the spin buttons for WebKit browsers */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Hide spinners in Firefox */
    input[type="number"] {
    -moz-appearance: textfield;
    }
  </style>
  